import * as LabelBase from "@radix-ui/react-label";
import clsx from "clsx";
import { forwardRef, InputHTMLAttributes, ReactNode, useEffect, useState } from "react";
import { getRandomId } from "../../../../utils/reusedFunks";

type inputSize = "big" | "small";
type iconAnchor = "left" | "right";

const sizeClasses = {
	big: "h-12",
	small: "h-9",
};

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	noLabel?: boolean;
	labelClassName?: string;

	error?: boolean;
	description?: string;
	showDescAlways?: boolean;

	inputSize?: inputSize;

	icon?: ReactNode;
	iconAnchor?: iconAnchor;
	onIconClick?(): void;

	onChangeDebounced?(value: string): void;
	debounceMs?: number;


	styleClasses?: {
		root?: string;
		input?: string;
	};
}

const TextBoxDefault = forwardRef<HTMLDivElement, Props>(
	(
		{
			label,
			noLabel,
			error,
			description,
			debounceMs = 500,
			inputSize = "big",
			icon,
			iconAnchor = "right",
			onChangeDebounced,
			onIconClick,
			styleClasses,
			className,
			labelClassName,
			onChange,
			value,
			...props
		}: Props,
		ref,
	) => {
		const id = getRandomId("tb");

		const [text, setText] = useState(props.defaultValue as string);

		useEffect(() => {
			const getData = setTimeout(() => {
				onChangeDebounced?.(text);
			}, debounceMs);

			return () => clearTimeout(getData);
		}, [text]);

		return (
			<div ref={ref} className={clsx("flex flex-col gap-1", styleClasses?.root)}>

				{!noLabel && (
					<LabelBase.Root className={`block h-4 text-xs text-gray-4 ${labelClassName}`} htmlFor={id}>
						{label}
					</LabelBase.Root>
				)}
				<div className="relative flex">
					<input
						placeholder={props.placeholder}
						readOnly={props.readOnly}
						className={clsx(
							"w-full rounded-lg border border-gray-2 px-3 text-sm outline-none disabled:bg-gray-1 disabled:text-gray-3",
							sizeClasses[inputSize],
							error && "border-error",
							icon && (iconAnchor === "left" ? "pl-10" : "pr-10"),
							className,
						)}
						value={value || ""}
						id={id}
						onChange={(e) => {
							if (onChangeDebounced) setText(e.target.value);
							onChange?.(e);
						}}
					/>
					{icon ? (
						<div
							className={clsx(
								"absolute top-1/2 my-auto -translate-y-1/2",
								iconAnchor === "left" ? "left-3" : "right-3",
								onIconClick ? "cursor-pointer" : "pointer-events-none",
							)}
							onClick={onIconClick}
						>
							{icon}
						</div>
					) : null}
				</div>
				{description && error ? (
					<LabelBase.Root
						className={clsx("block text-xs", error ? "text-error" : "text-gray-4")}
						htmlFor={id}
					>
						{description}
					</LabelBase.Root>
				) : null}
			</div>
		);
	},
);

TextBoxDefault.displayName = "TextBoxDefault";

export default TextBoxDefault;
