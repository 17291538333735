import { FC } from "react";
import InfoIcon from "../assets/icons/InfoIcon";
import PlusIcon from "../assets/icons/PlusIcon";
import TrashIcon from "../assets/icons/TrashIcon";
import { IReference } from "../assets/interfaces/IReference";
import systemeIcon from "../assets/systemeIcon.svg";
import Button from "./UI/buttons/Button";
import IconButton from "./UI/buttons/IconButton";
import Counter from "./UI/inputs/textInput/Counter";
import Tooltip from "./UI/Tooltip/Tooltip";
import { checkIsNumber } from "../utils/validators";
import { formatPriceToStr, getRefSum } from "../utils/reusedFunks";

type RefPlace = "bascket" | "unallocated" | "search"

interface IProps {
    renderPlace: RefPlace;
    data: IReference;
    infoIcon?: { color: string, text: string }
    deleteRef?: (refSku: string) => void;
    setCount?: (refSku: string, count: number, handleInput?: boolean, roundedUp?: boolean) => void;
    addRef?: (ref: IReference) => void;
}

const ReferenceCard: FC<IProps> = ({ renderPlace, data, addRef, deleteRef, setCount, infoIcon }) => {

    const refPrice = (data.count !== undefined && data.cost !== undefined && checkIsNumber(data.count) && checkIsNumber(data.cost)) ?
        getRefSum(data.count, data.cost) :
        NaN

    return <div className={`w-full flex items-center  gap-4 border-b py-4 border-gray-1 `}>
        <div className="w-10 h-10">
            <img src={data.img || systemeIcon} alt="IMG" />
        </div>
        <div className="flex flex-col flex-1 gap-2">
            <div className="flex items-center gap-1">
                <p className="text-accent font-semibold text-sm">{data.referenceSku}</p>
                {(renderPlace === "bascket") && <IconButton onClick={() => deleteRef && deleteRef(data.referenceSku)}
                    size="small"
                    className="text-gray-3">
                    <TrashIcon size={16} />
                </IconButton>}
                {(renderPlace === "bascket" && infoIcon) && <Tooltip side="right" text={infoIcon.text}><span><InfoIcon className={infoIcon.color} size={18} /></span></Tooltip>}
            </div>
            <div className="text-xs">{data.desc}</div>
            <div className="flex gap-1 items-center">
                <p className="text-xs text-gray-3">Линейка:</p>
                <p className="text-sm">{data.line}</p>
            </div>
        </div>
        <div>
            {renderPlace === "bascket" &&
                <div className="flex flex-col">
                    {checkIsNumber(refPrice) && <div className="self-end mb-2 text-[14px]"><span className="font-semibold">{formatPriceToStr(refPrice)}</span> ₽</div>}
                    <Counter size="small"
                        value={data.count as number}
                        setNumber={(count: number, handleInput, roundedUp) => setCount && setCount(data.referenceSku, count, handleInput, roundedUp)} />
                </div>}
            {renderPlace === "search" && <Button onClick={() => addRef && addRef(data)} noFish size="small" className="rounded-lg px-[6px] items-center" >
                <PlusIcon size={18} />
                <span>Добавить</span>
            </Button>}
        </div>
    </div>;
}

export default ReferenceCard;