import { Dispatch, SetStateAction, useState } from 'react';
import { PaylodParam } from '../../API/base/payloadApi/payloadInterfaces';
import { getProfileResponse } from '../../API/base/profileApi/profileInterfaces';
import { THEME_DECRAFT } from '../../assets/enums/Themes';
import CrossIcon from '../../assets/icons/CrossIcon';
import IconButton from '../UI/buttons/IconButton';
import ModalBase from '../UI/modals/ModalBase';
import ScrollArea from '../UI/wrappers/ScrollArea';
import SearchBox from '../UI/inputs/textInput/SearchBox';


interface IProps {
  cityList: PaylodParam[]
  profileInfo?: getProfileResponse;
  open?: boolean;

  setTempCityId: Dispatch<SetStateAction<string>>
  toggleOpen: Dispatch<SetStateAction<boolean>>
}

const ChangeCityModal = (props: IProps) => {
  const [searchText, setSearchText] = useState<string>('')

  const filteredCities = searchText ? props.cityList.filter((city) => city.name.toLowerCase().includes(searchText.toLowerCase())) : props.cityList

  return (
    <ModalBase className='w-[520px] max-h-[520px]' onOpenChange={() => props.toggleOpen(!props.open)} open={props.open}>
      <div className="w-full flex flex-col items-center">
        <span className="w-full flex items-start justify-end -mt-6 -mr-16 ">
          <IconButton className={`${THEME_DECRAFT && "text-white hover:text-accent transition-all"}`}
            onClick={() => props.toggleOpen(!props.open)} size={"small"}><CrossIcon size={THEME_DECRAFT ? 24 : 16} />
          </IconButton>
        </span>
        <div className="w-full flex flex-col items-center gap-4 -mt-2">
          <h1 className="w-full font-semibold text-lg">Выбрать город</h1>
          <SearchBox
            inputSize='small'
            placeholder='Поиск'
            className="border-t-0 border-l-0 border-r-0"
            onChangeText={(text: string) => setSearchText(text)}
          />
          <div className="w-full">
            <ScrollArea className="max-h-56 min-h-24">
              {filteredCities.map((el) =>
                <div key={el.id} onClick={() => {
                  props.setTempCityId(el.id)
                  localStorage.setItem('tempCityId', String(el.id))
                }
                } className='w-[440px] h-8 rounded-[8px] font-semibold hover:bg-gray-1 flex items-center px-1 transition-all cursor-pointer'>{el.name}
                </div>)}
            </ScrollArea>
          </div>
        </div>
      </div>
    </ModalBase>
  );
};

export default ChangeCityModal;