import * as LabelBase from "@radix-ui/react-label";
import clsx from "clsx";
import { forwardRef, InputHTMLAttributes, useState } from "react";
import EyeOpenedIcon from "../../../../assets/icons/EyeOpenedIcon";
import EyeClosedIcon from "../../../../assets/icons/EyeClosedIcon";
import { getRandomId } from "../../../../utils/reusedFunks";

type inputSize = "big" | "small";

const sizeClasses = {
	big: "h-12",
	small: "h-9",
};

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	inputSize?: inputSize;
	error?: boolean;
	description?: string;
}

const PasswordBox = forwardRef<HTMLDivElement, Props>(
	({ label, inputSize = "big", error, description, className, ...props }: Props, ref) => {
		const id = getRandomId("tb");

		const [showPass, setShowPass] = useState<boolean>(false);

		return (
			<div ref={ref} className="flex flex-col gap-1">
				<div className="relative">
					<input
						placeholder=" "
						className={clsx(
							"w-full rounded-lg border appearance-none border-gray-2 pt-3 px-3 text-sm outline-none peer disabled:bg-gray-1 disabled:text-gray-3",
							error && "border-error",
							sizeClasses[inputSize],
							className,
						)}
						id={id}
						type={showPass ? "text" : "password"}
						{...props}
					/>
					<div
						className="absolute right-3 top-1/2 my-auto -translate-y-1/2 cursor-pointer text-gray-3"
						onClick={() => setShowPass(!showPass)}
					>
						{showPass ? <EyeOpenedIcon /> : <EyeClosedIcon />}
					</div>
					<LabelBase.Root className="absolute text-xs text-gray-4 duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-3 peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3" htmlFor={id}>
						{label}
					</LabelBase.Root>
				</div>
				{error && description ? (
					<LabelBase.Root
						className={clsx("block text-xs", error ? "text-error" : "text-gray-4")}
						htmlFor={id}
					>
						{description}
					</LabelBase.Root>
				) : null}
			</div>
		);
	},
);

PasswordBox.displayName = "PasswordBox";

export default PasswordBox;
