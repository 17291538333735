import * as LabelBase from "@radix-ui/react-label";
import clsx from "clsx";
import { forwardRef, InputHTMLAttributes, ReactNode, useEffect, useState } from "react";
import { getRandomId } from "../../../../utils/reusedFunks";
import { THEME_DECRAFT } from "../../../../assets/enums/Themes";

type inputSize = "big" | "small";
type iconAnchor = "left" | "right";

enum themeRoundedStyle {
	default = "rounded-lg",
	decraft = "rounded-[3px]"
}

const sizeClasses = {
	big: "h-12",
	small: "h-9",
};

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	noLabel?: boolean;
	error?: boolean;
	description?: string;
	inputSize?: inputSize;
	icon?: ReactNode;
	iconAnchor?: iconAnchor;
	onIconClick?(): void;
	onChangeDebounced?(value: string): void;
	onChangeText?: (text: string) => void;
	debounceMs?: number;
	styleClasses?: {
		root?: string;
		input?: string;
	};
}

const TextBox = forwardRef<HTMLDivElement, Props>(
	(
		{
			label,
			noLabel,
			error,
			description,
			onChangeDebounced,
			debounceMs = 500,
			inputSize = "big",
			icon,
			iconAnchor = "right",
			onIconClick,
			styleClasses,
			onChangeText,
			className,
			...props
		}: Props,
		ref,
	) => {
		const [text, setText] = useState(props.defaultValue as string);

		useEffect(() => {
			const getData = setTimeout(() => {
				onChangeDebounced?.(text);
			}, debounceMs);

			return () => clearTimeout(getData);
		}, [text]);

		const id = getRandomId("tb");
		return <div ref={ref} className={clsx("flex relative flex-col gap-1", styleClasses?.root)}>
			<div className="relative flex">
				<input
					type="text"
					placeholder=" "
					className={clsx(
						`w-full border appearance-none border-neutral-content pt-3 px-3 text-sm outline-none peer
						disabled:bg-gray-1 disabled:text-gray-3`, THEME_DECRAFT ? themeRoundedStyle.decraft : themeRoundedStyle.default,
						sizeClasses[inputSize],
						error && "border-error",
						icon && (iconAnchor === "left" ? "pl-10" : "pr-10"),
						className,
					)}
					id={id}
					onChange={(e) => {
						if (onChangeDebounced) setText(e.target.value);
						onChangeText?.(e.target.value);
					}}
					{...props}
				/>
				{icon ? (
					<div
						className={clsx(
							"absolute top-1/2 my-auto -translate-y-1/2",
							iconAnchor === "left" ? "left-3" : "right-3",
							onIconClick ? "cursor-pointer" : "pointer-events-none",
						)}
						onClick={onIconClick}
					>
						{icon}
					</div>
				) : null}
				{!noLabel && (
					<LabelBase.Root className={`absolute select-none text-xs text-gray-4 duration-300 transform -translate-y-3 top-4 z-10 origin-[0] left-3 peer-placeholder-shown:text-sm peer-placeholder-shown:-translate-y-1 peer-focus:text-xs peer-focus:-translate-y-3`} htmlFor={id}>
						{label}
					</LabelBase.Root>
				)}
			</div>
			{error && description ? (
				<LabelBase.Root
					className={clsx("block text-xs", error ? "text-error" : "text-gray-4")}
					htmlFor={id}
				>
					{description}
				</LabelBase.Root>
			) : null}
		</div>
	},
);

TextBox.displayName = "TextBox";

export default TextBox;
