import { FC, useState } from "react";
import Button from "../../UI/buttons/Button";
import AdminAdmins from "./parts/AdminAdmins";
import AdminAnalytics from "./parts/AdminAnalytics/AdminAnalytics";
import AdminDBProduct from "./parts/AdminDBProduct";
import AdminPartners from "./parts/AdminPartners/AdminPartners";
import AdminUsers from "./parts/AdminUsers";
import clsx from "clsx";
import { GetAdminProfileResponse } from "../../../API/admin/adminAuthApi/AdminAuthInterfaces";
import EditPartnerWindow from "./parts/AdminPartners/components/EditPartnerWindow";
import { IPartnerAdmin } from "../../../assets/interfaces/IPartner";
import LoaderModal from "../../UI/loader/LoaderModal";
import AdminLogs from "./parts/AdminLogs/AdminLogs";


interface IProps {
  onLogout: () => void;
  adminProfileInfo: GetAdminProfileResponse;
}

export enum EManagerServices {
  Partners = "Партнеры",
  Analytics = "Аналитика",
}

export enum EAdminServices {
  Logs = "Логи",
  DBProduct = "БД продуктов",
  Partners = "Партнеры",
  Analytics = "Аналитика",
  Users = "Пользователи",
  Admins = "Администраторы",
}

const AdminPage: FC<IProps> = ({ onLogout, adminProfileInfo }) => {
  const [currentPage, setCurrentPage] = useState<EAdminServices | EManagerServices>(
    EAdminServices.Analytics
  );



  const renderPage = () => {
    switch (currentPage) {
      case EAdminServices.Logs:
        return <AdminLogs />;
      case EAdminServices.DBProduct:
        return <AdminDBProduct />;
      case EAdminServices.Partners:
        return <AdminPartners userRole={adminProfileInfo.role}/>;
      case EAdminServices.Analytics:
        return <AdminAnalytics />;
      case EAdminServices.Users:
        return <AdminUsers />;
      case EAdminServices.Admins:
        return <AdminAdmins />;
      default:
        return null;
    }
  };

  const navMenuItems = adminProfileInfo && Object.values(adminProfileInfo.role === "admin" ? EAdminServices : EManagerServices).map((service, i) => {
    const isActive = service === currentPage
    const activeIndex = Object.values(EAdminServices).findIndex((s) => s === currentPage)
    return (
      <span className="flex items-center" key={service}>
        <Button
          size="big"
          variant="secondary"
          rounded="small"
          className={clsx("w-fit rounded-b-none text-black !px-6 border-none",
            isActive && "!bg-white"
          )}
          noFish
          onClick={() => setCurrentPage(service)}
        >
          {service}
        </Button>
        {(i < Object.values(EAdminServices).length - 1) && <span className={clsx("h-1/2 w-[1px]", !isActive && (i !== activeIndex - 1) && "bg-gray-2")} />}
      </span>
    )
  })

  return (
    <div className="bg-gray-5 h-screen w-full">
      {adminProfileInfo ? <div className="h-full w-full flex flex-col overflow-hidden">
        <div className="bg-gray-5 h-[152px]">
          <div className="flex items-center justify-center py-8 relative">
            <h1 className="text-4xl">{adminProfileInfo.role === "partner" ? "Панель партнера" : "Панель администратора"}</h1>
            {adminProfileInfo && <div className="absolute right-4 flex gap-4 items-center bg-gray-2 p-4 rounded-lg">
              <div className="flex flex-col items-center">
                <p className="font-semibold">{adminProfileInfo.role === "partner" ? `${adminProfileInfo.partnerData?.name}` : `${adminProfileInfo.firstName} ${adminProfileInfo.lastName}`}</p>
                <p className="text-gray-3">({adminProfileInfo.role})</p>
              </div>
              <Button onClick={onLogout} size="small" className="rounded-lg !w-[100px]" noFish>Выйти</Button>
            </div>}
          </div>
          {!(adminProfileInfo.role === "partner") && <div className="flex justify-center">
            {navMenuItems}
          </div>}
        </div>
        <div className="flex-grow bg-white flex-1 py-4 overflow-y-auto">{adminProfileInfo.role === "partner" ?
          <EditPartnerWindow userRole={adminProfileInfo.role} partnerData={{ ...adminProfileInfo.partnerData, id: adminProfileInfo.id, login: adminProfileInfo.login } as IPartnerAdmin} /> : renderPage()}</div>
        <div className="bg-gray-5 text-center py-2 h-10">© {new Date().getFullYear()}</div>
      </div> : <LoaderModal open />}
    </div>
  );
};

export default AdminPage;
