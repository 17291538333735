import clsx from "clsx";
import { FC } from "react";
import logo from "../../assets/logoBig.svg";
import logoDecraft from "../../assets/logoBigDecraft.svg"
import VkIcon from "../../assets/icons/VkIcon";
import YouTubeIcon from "../../assets/icons/YouTubeIcon";
import TelegramIcon from "../../assets/icons/TelegramIcon";
import { THEME_DECRAFT } from "../../assets/enums/Themes";

interface IProps {
    isMobile: boolean
}

const LendingFooter: FC<IProps> = (props: IProps) => {
    return (<div className={clsx("w-full bg-gray-1 p-4 sm:p-6 flex", "flex-col sm:flex-row gap-1")}>
        <div className="sm:flex-1 flex flex-col xl:flex-row gap-8 sm:gap-10">
            <div className="xl:flex-1">
                <img src={THEME_DECRAFT ? logoDecraft : logo} />
            </div>
            <div className="flex-1 h-full flex flex-col gap-1 sm:gap-2 items-start">
                <p className="text-accent font-semibold text-sm pb-2 sm:pb-0">
                    Центр Поддержки Клиентов
                </p>
                {!props.isMobile && <a target="_blank" href="https://t.me/CCCSupportSysteme_bot" className="text-gray-3 hover:text-accent transition-colors font-semibold">Телеграм-чат</a>}
                <p className="flex flex-col text-gray-3 text-xl font-semibold">
                    <a className="hover:text-accent transition-colors" href="tel:8 800 200 64 46">8 800 200 64 46</a>
                    <a className="hover:text-accent transition-colors" href="tel:+7 495 777 99 88">8 495 777 99 88</a>
                </p>
                <a className="text-gray-3 mb-4 hover:text-accent transition-colors" href="mailto:support@systeme.ru">support@systeme.ru</a>
                {!props.isMobile && <a target="_blank" href="https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh"
                    className="text-gray-3 text-xs w-fit hover:text-accent transition-colors">Политика конфиденциальности <br /> © 2024 «Систэм Электрик» </a>}
            </div>
        </div>
        <div className="flex-1 flex flex-col sm:flex-col-reverse xl:flex-row gap-6 sm:gap-10">
            <div className="sm:flex-1 sm:h-full flex flex-col gap-1">
                <p className="text-accent font-semibold ">
                    Адрес
                </p>
                <p className="text-gray-3 text-sm pb-2 sm:pb-0">Москва, ул. Двинцев, д. 12, корп.1, <br /> здание «А»</p>
                <p className="text-accent font-semibold ">
                    {!props.isMobile ? <>Шоурум дизайнерской электрики <br /> Systeme Electric</> : <>Контакты для прессы</>}
                </p>
                <p className="text-gray-3 text-sm mb-2">
                    {!props.isMobile ?
                        <>Москва, ул. Нижняя Сыромятническая, <br /> д. 10, стр. 9</> :
                        <a href="mailto:systeme@m-p.ru">systeme@m-p.ru</a>
                    }
                </p>
                {!props.isMobile && <a target="_blank" href="https://workspace.systeme.ru/s/KcRoG2PYNRr36gq" className="text-gray-3 font-semibold cursor-pointer w-fit text-xs hover:text-accent transition-colors">
                    Пользовательское соглашение
                </a>}
            </div>
            <div className="flex xl:flex-1 xl:justify-end gap-3 pb-2 sm:pb-0">
                <a className="text-accent bg-white h-11 w-11 flex items-center justify-center rounded-full hover:bg-accent hover:text-white transition-colors" target="_blank" href="https://vk.com/systemeelectric"><VkIcon /></a>
                <a className="text-accent bg-white h-11 w-11 flex items-center justify-center rounded-full hover:bg-accent hover:text-white transition-colors" target="_blank" href="https://www.youtube.com/channel/UCfSQiFykGRQRb6dq1-mznrw"><YouTubeIcon /></a>
                <a className="text-accent bg-white h-11 w-11 flex items-center justify-center rounded-full hover:bg-accent hover:text-white transition-colors" target="_blank" href="https://t.me/CCCSupportSysteme_bot"><TelegramIcon /></a>
            </div>
            {props.isMobile && <a target="_blank" href="https://workspace.systeme.ru/s/KcRoG2PYNRr36gq" className="text-gray-3 font-semibold cursor-pointer w-fit text-xs hover:text-accent transition-colors">
                Пользовательское соглашение
            </a>}
            {props.isMobile && <a target="_blank" href="https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh"
                className="text-gray-3 text-xs hover:text-accent transition-colors">Политика конфиденциальности <br /> © 2024 «Систэм Электрик» </a>}
        </div>
    </div>);
}

export default LendingFooter;