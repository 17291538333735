export enum EReportDate {
    Week = "week",
    Month = "month",
    Year = "year",
    Day = "day",
    All = "all",
    Custom = "custom",
}

export enum EReportPartnersExportType {
    All = "all",
    Selected = "selected",
}

export enum EReportType {
    Partners = "partners",
    References = "references",
    Users = "users"
}

export enum EExportType {
    CSV = "csv",
    XLSX = "xlsx",
}

export enum EReportDataType {
    All = "all",
    Cart = "cart",
    Exchange = "exchange",
}

export interface IReportDates {
    value: EReportDate;
    label: string;
}

export const ReportDateTitles: Record<EReportDate, string> = {
    [EReportDate.Week]: "Неделя",
    [EReportDate.Month]: "Месяц",
    [EReportDate.Year]: "Год",
    [EReportDate.All]: "Все время",
    [EReportDate.Custom]: "Пользовательский",
    [EReportDate.Day]: "День",
};

export const ReportDates: IReportDates[] = [
    { value: EReportDate.Week, label: ReportDateTitles[EReportDate.Week] },
    { value: EReportDate.Month, label: ReportDateTitles[EReportDate.Month] },
    { value: EReportDate.Year, label: ReportDateTitles[EReportDate.Year] },
    { value: EReportDate.All, label: ReportDateTitles[EReportDate.All] },
    { value: EReportDate.Custom, label: ReportDateTitles[EReportDate.Custom] },
];

export enum EReportSplitDate {
    Week = "week",
    Month = "month",
    Year = "year",
    Day = "day",
}

export interface IReportSplitDates {
    value: EReportSplitDate;
    label: string;
}

export const ReportSlitDateTitles: Record<EReportSplitDate, string> = {
    [EReportSplitDate.Week]: "Неделя",
    [EReportSplitDate.Month]: "Месяц",
    [EReportSplitDate.Year]: "Год",
    [EReportSplitDate.Day]: "День",
};

export const ReportSplitDates: IReportSplitDates[] = [
    {
        value: EReportSplitDate.Week,
        label: ReportSlitDateTitles[EReportSplitDate.Week],
    },
    {
        value: EReportSplitDate.Month,
        label: ReportSlitDateTitles[EReportSplitDate.Month],
    },
    {
        value: EReportSplitDate.Year,
        label: ReportSlitDateTitles[EReportSplitDate.Year],
    },
    {
        value: EReportSplitDate.Day,
        label: ReportSlitDateTitles[EReportSplitDate.Day],
    },
];

export enum ESplitType {
    Lines = "line",
    Time = "time",
    Partners = "partners",
}

export interface IReport {
    // Общее
    type: EReportType;
    period: EReportDate;
    format: EExportType;
    reportDataType?: EReportDataType;
    selectType?: EReportPartnersExportType;
    date?: {
        start?: Date;
        end?: Date;
    };
    selected?: string[];
    split?: EReportSplitDate;
    splitGroup?: ESplitType;

    // Параметры отчета
    transactionsValue?: boolean;
    transactionsSummary?: boolean;

    // Партнеры
    transitValue?: boolean;
    totalPartnerCartCost?: boolean;
    averageUserCartCost?: boolean;
    nomenclaturePartEquipInUserCart?: boolean;
    moneyPartEquipInUserCart?: boolean;

    // Референсы
    lines?: string[];
    addictionNumber?: boolean;
    averageCountInUserCart?: boolean;
}

