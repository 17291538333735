import { useEffect, useState } from "react";
import ReactInputVerificationCode from "react-input-verification-code";
import TextBox from "../UI/inputs/textInput/TextBox";
import PasswordBox from "../UI/inputs/textInput/PasswordBox";
import Checkbox from "../UI/inputs/checkBox/CheckBox";
import Button from "../UI/buttons/Button";
import Tooltip from "../UI/Tooltip/Tooltip";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import { ResponseContainer } from "../../API/BaseApi";
import {
  Login,
  ResetPassword,
  VerifiConfirm,
  Verification,
} from "../../API/base/authRegApi/authRegFunctions";
import Loader from "../UI/loader/loader";
import { THEME_DECRAFT } from "../../assets/enums/Themes";

interface IProps {
  onAuth(): void;
  isMobile: boolean;
  onOpenSupport: () => void;
}

const AuthForm = (props: IProps) => {
  type RecoveryStep = "inputEmail" | "inputCode" | "inputPass";
  const [isRecoveryPass, setIsRecoveryPass] = useState<boolean>(false);
  const [recoveryStep, setRecovferyStep] = useState<RecoveryStep>("inputEmail");
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [stayInSysteme, toggleStayInSysteme] = useState<boolean>(true);
  const [authAccess, setAuthAccess] = useState(false);
  const [timerValue, setTimerValue] = useState<number>(0);
  const [timerIsStart, setTimerIsStart] = useState<boolean>(false);
  const [getCodeCount, setGetCodeCount] = useState<number>(0);
  const [code, setCode] = useState<string>("");
  const [codeError, setCodeError] = useState<boolean>(false);
  const [failCount, setFailCount] = useState<number>(0);
  const [newPassword, setNewPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [newPasswordError, setNewPasswordError] = useState<boolean>(false);
  const [repeatPasswordError, setRepeatPasswordError] =
    useState<boolean>(false);
  const [recoveryAccess, setRecoveryAccess] = useState<boolean>(false);
  const [recoveryEmail, setRecoveryEmail] = useState<string>("");
  const [recoveryEmailError, setRecoveryEmailError] = useState<boolean>(false);
  const [recoveryEmailErrorText, setRecoveryEmailErrorText] =
    useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let intervalId: any;

  useEffect(() => {
    if (
      newPassword &&
      !newPasswordError &&
      repeatPassword &&
      !repeatPasswordError
    ) {
      setRecoveryAccess(true);
    } else {
      setRecoveryAccess(false);
    }
  }, [newPassword, repeatPassword, newPasswordError, repeatPasswordError]);

  useEffect(() => {
    const isValid = (r: string) => {
      return (
        r.length >= 8 &&
        r.length <= 16 &&
        /^[a-zA-z0-9 !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~.]+$/.test(r)
      );
    };
    if (newPassword === repeatPassword) {
      setRepeatPasswordError(false);
    } else {
      setRepeatPasswordError(true);
    }
    if (isValid(newPassword) || newPassword.length === 0) {
      setNewPasswordError(false);
    } else {
      setNewPasswordError(true);
    }
  }, [newPassword, repeatPassword]);

  useEffect(() => {
    if (failCount === 3) {
      props.onOpenSupport();
    }
  }, [failCount]);

  useEffect(() => {
    if (timerIsStart) {
      let seconds = 60;
      setTimerValue(seconds);
      const tickFunk = () => {
        if (seconds > 0) {
          seconds--;
          setTimerValue(seconds);
        } else {
          clearInterval(intervalId);
          setTimerIsStart(false);
        }
      };
      intervalId = setInterval(tickFunk, 1000);
    }
  }, [timerIsStart]);

  useEffect(() => {
    if (!error && !emailError && password && email) {
      setAuthAccess(true);
    } else {
      setAuthAccess(false);
    }
  }, [error, emailError, password, email]);

  const authHandler = () => {
    Login({ username: email, password: password }).then(
      (r: ResponseContainer<null>) => {
        onAuth(r);
      }
    );
  };

  const onAuth = (resp: ResponseContainer<null>) => {
    if (resp.status === "error") {
      setError(true);
    } else {
      props.onAuth();
    }
  };

  const onSetLogin = (email: string) => {
    if (
      email.match("(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$)") ||
      email.length === 0
    ) {
      setError(false);
      setEmailError(false);
      setEmail(email);

      setRecoveryEmail(email);
      setRecoveryEmailError(false);
      setRecoveryEmailErrorText("");
    } else {
      setEmailError(true);

      setRecoveryEmailError(true);
    }
  };

  const onGetCode = () => {
    setIsLoading(true);
    Verification(recoveryEmail, true).then((r: ResponseContainer<null>) => {
      if (r.status !== "error") {
        if (recoveryStep !== "inputCode") {
          setRecovferyStep("inputCode");
        }
        setTimerIsStart(true);
        setGetCodeCount(getCodeCount + 1);
      } else {
        setRecoveryEmailError(true);
        setRecoveryEmailErrorText(r.message || "Пользователь не найден");
      }
      setIsLoading(false);
    });
  };

  const onSendCode = () => {
    VerifiConfirm({ codeFromEmail: code, email: recoveryEmail }).then(
      (r: ResponseContainer<null>) => {
        if (r.status !== "error") {
          setRecovferyStep("inputPass");
        } else {
          setCodeError(true);
          setFailCount(failCount + 1);
        }
      }
    );
  };

  const onTryRecovery = () => {
    ResetPassword(newPassword, recoveryEmail).then(
      (r: ResponseContainer<null>) => {
        if (r.status !== "error") {
          setIsRecoveryPass(false);
          setRecovferyStep("inputEmail");
        } else {
          setNewPasswordError(true);
        }
      }
    );
  };

  const newPassRules = (
    <div className="flex flex-col gap-2">
      <p>Требования и допустимые символы:</p>
      <div className="flex text-gray-2 pl-3">
        <li />
        <p className="ml-[-6px]">Длина от 8 до 16 символов;</p>
      </div>
      <div className="flex text-gray-2 pl-3">
        <li />
        <p className="ml-[-6px]">
          Строчные и заглавные буквы латинского алфавита;
        </p>
      </div>
      <div className="flex text-gray-2 pl-3">
        <li />
        <p className="ml-[-6px]">Цифры от 0 до 9;</p>
      </div>
      <div className="flex text-gray-2 pl-3">
        <li />
        <p className="ml-[-6px]">
          {
            "Символы: ! \" # $ % & ' ( ) * + , - . / : ; < = > ? @ [  ] ^ _` { | } ~."
          }
        </p>
      </div>
    </div>
  );
  return (
    <div className="flex w-full flex-col gap-8 pt-2 pb-8">
      {!isRecoveryPass ? (
        <div className="flex w-full flex-col gap-4 sm:gap-8">
          <div className="flex w-full flex-col gap-2 sm:gap-6">
            <TextBox
              error={emailError}
              defaultValue={email}
              description="Некорректный e-mail"
              required
              label="E-mail"
              onChange={(ev) => {
                onSetLogin(ev.target.value);
              }}
              onKeyUp={(ev) => {
                if (ev.key === "Enter" && authAccess) authHandler();
              }}
            />
            <PasswordBox
              className="mb-2 sm:mb-0"
              description="Неверный логин или пароль"
              error={error}
              label="Пароль"
              required
              onChange={(ev) => {
                setPassword(ev.target.value);
                setError(false);
              }}
              onKeyUp={(ev) => {
                if (ev.key === "Enter" && authAccess) authHandler();
              }}
            />
            <Checkbox
              checked={stayInSysteme}
              label="Оставаться в сети"
              onCheckedChange={() => toggleStayInSysteme(!stayInSysteme)}
            />
          </div>
          <Button
            noFish
            className={`${THEME_DECRAFT && "rounded-lg"}`}
            disabled={!authAccess}
            onClick={() => {
              authHandler();
            }}
          >
            Войти
          </Button>
          <div className="w-auto sm:w-[65%] flex gap-2 sm:gap-6 mx-auto text-center">
            <div className="sm:flex-1 text-sm sm:text-base">
              <p>Забыли пароль?</p>
            </div>
            <div
              className="flex-1 text-sm sm:text-base"
              onClick={() => setIsRecoveryPass(true)}
            >
              <p className="text-accent cursor-pointer hover:text-accent-dark transition-colors">
                Восстановить
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full flex-col gap-8">
          {recoveryStep === "inputEmail" && (
            <div className="flex w-full flex-col gap-3 sm:gap-6">
              <div
                onClick={() => setIsRecoveryPass(false)}
                className="flex cursor-pointer text-accent items-center select-none hover:text-accent-dark transition-colors"
              >
                <ArrowRightIcon size={16} className="rotate-180" />
                <p>Назад ко входу</p>
              </div>
              <div className="flex flex-col gap-3 sm:gap-4">
                <h1 className="text-2xl font-semibold">
                  Восстановление пароля
                </h1>
                <p className="text-sm">
                  Пожалуйста, введите адрес электронной почты, которая привязана
                  к вашему аккаунту.
                </p>
              </div>
              <TextBox
                className="mb-4"
                error={recoveryEmailError}
                defaultValue={email}
                description={recoveryEmailErrorText || "Некорректный e-mail"}
                required
                label="E-mail"
                onChange={(ev) => {
                  onSetLogin(ev.target.value);
                }}
                onKeyUp={(ev) => {
                  if (ev.key === "Enter" && authAccess) onGetCode();
                }}
              />
              {isLoading ? (
                <Loader />
              ) : (
                <Button
                  noFish
                  disabled={!recoveryEmail || recoveryEmailError}
                  onClick={() => {
                    onGetCode();
                  }}
                >
                  Продолжить
                </Button>
              )}
            </div>
          )}
          {recoveryStep === "inputCode" && (
            <div className="flex w-full flex-col gap-3 sm:gap-4">
              <div
                onClick={() => setRecovferyStep("inputEmail")}
                className="flex cursor-pointer text-accent items-center select-none hover:text-accent-dark transition-colors"
              >
                <ArrowRightIcon size={16} className="rotate-180" />
                <p>Назад к почте</p>
              </div>
              <div className="flex flex-col gap-3 sm:gap-4">
                <h1 className="text-2xl font-semibold">
                  Восстановление пароля
                </h1>
                <p className="text-sm">
                  На почтовый ящик{" "}
                  <span className="font-semibold">{recoveryEmail}</span> было
                  отправлено письмо с кодом подтверждения. Введите код для
                  подтверждения в поле ниже. В случае необнаружения письма
                  проверьте папку «Спам».
                </p>
              </div>
              <ReactInputVerificationCode
                onChange={(code: string) => {
                  setCodeError(false);
                  setCode(code);
                }}
                placeholder={""}
                autoFocus
                length={6}
              />
              <p className="text-xs text-error h-4">
                {codeError && "Неверный код подтверждения"}
              </p>
              <Button
                noFish
                disabled={code.length <= 5 || codeError}
                onClick={() => {
                  onSendCode();
                }}
              >
                Продолжить
              </Button>
              {getCodeCount <= 2 && (
                <div>
                  {!timerValue ? (
                    <Button
                      noFish
                      variant="secondary"
                      onClick={() => {
                        onGetCode();
                      }}
                    >
                      Отправить код повторно
                    </Button>
                  ) : (
                    <p className="text-center text-gray-4">
                      {`Отправить код повторно можно через 0:${
                        timerValue >= 10 ? timerValue : `0${timerValue}`
                      }`}
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
          {recoveryStep === "inputPass" && (
            <div className="flex w-full flex-col gap-2 sm:gap-6">
              <div
                onClick={() => {
                  setIsRecoveryPass(false);
                  setRecovferyStep("inputEmail");
                }}
                className="flex cursor-pointer text-accent items-center select-none hover:text-accent-dark transition-colors"
              >
                <ArrowRightIcon size={16} className="rotate-180" />
                <p>Назад ко входу</p>
              </div>
              <h1 className="text-2xl font-semibold mb-2 sm:mb-0">
                Восстановление пароля
              </h1>
              <div className="w-full">
                <Tooltip
                  side={props.isMobile ? "bottom" : "right"}
                  dropChild={newPassRules}
                  open={newPasswordError}
                >
                  <PasswordBox
                    description="Пароль не соответствует требованиям"
                    error={newPasswordError}
                    label="Новый пароль"
                    required
                    onChange={(ev) => {
                      setNewPassword(ev.target.value);
                    }}
                  />
                </Tooltip>
              </div>
              <PasswordBox
                description="Указанные пароли не совпадают"
                error={repeatPasswordError}
                label="Повторите пароль"
                required
                onChange={(ev) => {
                  setRepeatPassword(ev.target.value);
                }}
              />
              <Button
                noFish
                disabled={!recoveryAccess}
                onClick={() => {
                  onTryRecovery();
                }}
              >
                Изменить пароль
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AuthForm;
