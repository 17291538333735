import { FC, useEffect, useState } from "react";
import ModalBase from "../UI/modals/ModalBase";
import IconButton from "../UI/buttons/IconButton";
import CrossIcon from "../../assets/icons/CrossIcon";
import SearchBox from "../UI/inputs/textInput/SearchBox";
import Button from "../UI/buttons/Button";
import ScrollArea from "../UI/wrappers/ScrollArea";
import { IReference } from "../../assets/interfaces/IReference";
import ReferenceCard from "../ReferenceCard";
import { GetReferences } from "../../API/base/exchangeApi/exchangeFunctions";
import { ResponseContainer } from "../../API/BaseApi";
import { getReferencesResponse } from "../../API/base/exchangeApi/exchangeInterfaces";
import { scrollListTop } from "../../utils/reusedFunks";
import Loader from "../UI/loader/loader";
import { THEME_DECRAFT } from "../../assets/enums/Themes";

interface IProps {
    isOpen: boolean;
    addedRefsCount: { referenceSku: string, count: number | undefined }[];
    toggleOpen: () => void;
    addRef: (ref: IReference) => void;
    deleteRef: (refSku: string) => void;
    changeRefCount: (refSku: string, count: number, handleInput?: boolean, roundedUp?: boolean) => void;
}

const AddRefModal: FC<IProps> = ({ isOpen, toggleOpen, addedRefsCount, addRef, deleteRef, changeRefCount }) => {
    const ItemsPageSize = 10;
    const [searchText, setSearchText] = useState<string>("")
    const [references, setReferences] = useState<IReference[]>([])
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalRefsCount, setTotalRefsCount] = useState<number>(0)
    const [refsLoaded, setRefsLoaded] = useState<boolean>(false)
    const [isloading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (currentPage !== 0) {
            onGetReferences(currentPage)
        }
    }, [currentPage])

    useEffect(() => {
        setRefsLoaded(false)
    }, [searchText])

    const onGetReferences = (page: number) => {
        if (searchText && searchText.trim().length !== 0) {
            setIsLoading(true)
            GetReferences({ pagination: { page: page, pageSize: ItemsPageSize }, searchText: searchText }).then((r: ResponseContainer<getReferencesResponse>) => {
                if (r.status !== "error") {
                    if (page === 0) {
                        setReferences(r.data?.references as IReference[])
                    }
                    else {
                        setReferences([...references, ...r.data?.references as IReference[]])
                    }
                    setTotalRefsCount(r.data?.totalCount as number)
                    setRefsLoaded(true)
                }
                setIsLoading(false)
            })
        }
    }


    const onScrollEnd = () => {
        if (totalRefsCount > references.length) {
            setCurrentPage(currentPage + 1)
        }
    }

    const referencesList = references.map((r: IReference) => {
        const thisRefAdded = addedRefsCount.findIndex((addedRef) => addedRef.referenceSku === r.referenceSku) >= 0
        let addedRefData = undefined;
        if (thisRefAdded) {
            const addedRefCount = addedRefsCount.find((refCount) => refCount.referenceSku === r.referenceSku)?.count
            addedRefData = { ...r, count: addedRefCount }
        }
        return <ReferenceCard addRef={(ref: IReference) => addRef(ref)}
            deleteRef={(refSku: string) => deleteRef(refSku)}
            setCount={(refSku: string, count: number, handleInput, roundedUp) => changeRefCount(refSku, count, handleInput, roundedUp)}
            key={r.referenceSku}
            renderPlace={!thisRefAdded ? "search" : "bascket"}
            data={!thisRefAdded ? r : addedRefData as IReference} />
    })
    return (
        <ModalBase className={`rounded-2xl ${refsLoaded ? "" : "!pb-2"}!pb-2 !px-0`} onOpenChange={toggleOpen} open={isOpen}>
            <div className="w-[720px] flex flex-col items-center">
                <span className="w-full flex items-start justify-end -mt-6 -mr-16 px-10">
                    <IconButton className={`${THEME_DECRAFT && "text-white hover:text-accent transition-all"}`} onClick={toggleOpen} size={"small"}><CrossIcon size={THEME_DECRAFT ? 24 : 16}/></IconButton>
                </span>
                <div className="w-full flex flex-col items-center gap-4 -mt-2">
                    <h1 className="w-full font-semibold text-lg px-10">Добавить референс</h1>
                    <div className="flex gap-3 items-center w-full px-10">
                        <SearchBox onKeyUp={(ev) => {
                            if (ev.key === "Enter" && searchText) onGetReferences(0);
                        }} onClear={() => {
                            setCurrentPage(0)
                            scrollListTop("searchRefScroll")
                            setReferences([])
                        }} parentControl value={searchText} onChangeText={setSearchText} className="border-t-0 border-r-0 border-l-0 rounded-none !h-10 flex-1"
                            placeholder="Поиск" />
                        <Button disabled={searchText.trim().length === 0} onClick={() =>{ 
                            scrollListTop("searchRefScroll")
                            onGetReferences(0)
                            setCurrentPage(0)

                        }} size="small" className="w-[70px] !text-sm !font-medium rounded-lg" noFish>Найти</Button>
                    </div>
                    <div className="w-full">
                        <ScrollArea id = "searchRefScroll" onScrollEnd={onScrollEnd} className="max-h-56 min-h-24">
                            {references.length > 0 ? <div className="w-full min-h-24 flex flex-col justify-center px-10">
                                {referencesList}
                            </div> :
                                <div className="min-h-24 flex justify-center px-10 items-center">
                                   {!isloading ? <span className="w-64 text-center m-auto text-gray-3 font-normal text-base">
                                        {!refsLoaded ? "Здесь будут отображены результаты поиска" : "По вашему запросу нет результатов"}
                                    </span> : <Loader/>}
                                </div>}
                        </ScrollArea>
                    </div>
                </div>
            </div>
        </ModalBase>
    );
}

export default AddRefModal;