import { FC, useCallback, useEffect, useState } from "react";
import ModalBase from "../UI/modals/ModalBase";
import IconButton from "../UI/buttons/IconButton";
import CrossIcon from "../../assets/icons/CrossIcon";
import Button from "../UI/buttons/Button";
import clsx from "clsx";
import TextBoxDefault from "../UI/inputs/textInput/TextBoxDefault";
import { IUser } from "../../assets/interfaces/IUser";
import Select from "../UI/inputs/dropdown/Select";
import PasswordBox from "../UI/inputs/textInput/PasswordBox";
import { CreateUserRequest, EditUserRequest } from "../../API/admin/usersApi/usersInterfaces";
import { GetPayloadResponse, PaylodParam } from "../../API/base/payloadApi/payloadInterfaces";
import { GetPayload } from "../../API/base/payloadApi/payloadFunctions";
import { checkEmail, checkInn, checkIsNumberStr } from "../../utils/validators";
import { THEME_DECRAFT } from "../../assets/enums/Themes";
import { ResponseContainer } from "../../API/BaseApi";
import { CITY_TO_SHOW } from "../../assets/enums/CityPagination";


interface IProps {
  isOpen: boolean;
  toggleOpen: () => void;
  createFunk?: (userData: CreateUserRequest) => void;
  editFunk?: (userData: EditUserRequest, userId: string) => void;
  className?: string;
  renderFor: "edit" | "create";
  userData?: IUser
}

const CreateOrEditUserModal: FC<IProps> = ({ isOpen, toggleOpen, className, createFunk, editFunk, renderFor, userData }) => {
  const [userName, setUserName] = useState<string>();
  const [userLastName, setUserLastName] = useState<string>();
  const [userEmail, setUserEmail] = useState<string>();
  const [userCityId, setUserCityId] = useState<string>();
  const [userCompany, setUserCompany] = useState<string>();
  const [userInn, setUserInn] = useState<string>();
  const [userSpecialization, setUserSpecialization] = useState<string>();
  const [userPassword, setUserPassword] = useState<string>();
  const [contuneAccess, setContuneAccess] = useState<boolean>(false);
  const [innError, setInnError] = useState<boolean>(false)
  const [emailError, setEmailError] = useState<boolean>(false)
  const [specializations, setSpecializations] = useState<PaylodParam[]>()
  const [visibleCities, setVisibleCities] = useState(25);
  const [cityList, setCityList] = useState<PaylodParam[]>([])
  const citiesToShow = cityList.slice(0, visibleCities);

  useEffect(() => {
    onGetPayload()
  }, [])


  useEffect(() => {
    if ((userName && userName.length > 0) && (userLastName && userLastName.length > 0) && (userEmail && userEmail.length > 0)) {
      if (renderFor === "create" && (userCityId && userCityId.length > 0) && (userCompany && userCompany.length > 0) && (userInn && userInn.length > 0) &&
        userSpecialization && (userPassword && userPassword.length > 0)) {
        setContuneAccess(true);
      }
      else {
        setContuneAccess(false);
      }
      if (renderFor === "edit") {
        if (userName !== userData?.firstName || userLastName !== userData.lastName || userEmail !== userData.email) {
          setContuneAccess(true);
        } else {
          setContuneAccess(false);
        }
      }
    } else {
      setContuneAccess(false);
    }
  }, [userName, userLastName, userEmail, userCityId,
    userCompany, userInn, userSpecialization, userPassword]);

  useEffect(() => {
    if (renderFor === "create") {
      GetPayload({ specializations: true }).then((r) => {
        if (r.status !== "error") {
          setSpecializations(r.data?.specializations)
        }
      })
    }
  }, [renderFor])

  const onHandleLoadMoreCities = useCallback(() => {
    setVisibleCities(prev => prev + CITY_TO_SHOW);
  }, []);

  const onGetPayload = () => {
    GetPayload({ cities: true }).then((r: ResponseContainer<GetPayloadResponse>) => {
      setCityList(r.data?.cities || [])
    })
  }

  const onSendForm = () => {
    if (renderFor === "create") {
      const data: CreateUserRequest = {
        cityId: userCityId as string,
        companyName: userCompany as string,
        email: userEmail as string,
        firstName: userName as string,
        inn: userInn as string,
        lastName: userLastName as string,
        password: userPassword as string,
        specializationId: userSpecialization as string
      }
      createFunk && createFunk(data)
    }
    else {
      const data = {} as EditUserRequest
      if (userData?.firstName !== userName && userName) data.firstName = userName;
      if (userData?.lastName !== userLastName && userLastName) data.lastName = userLastName;
      if (userData?.email !== userEmail && userEmail) data.email = userEmail;
      editFunk && editFunk(data, userData?.id as string)
    }
  }

  const onSetInn = (innText: string) => {
    if (checkIsNumberStr(innText)) {
      if (checkInn(innText)) {
        setInnError(false)
      }
      else {
        setInnError(true)
      }
      setUserInn(innText)
    }
  }

  const onSetEmail = (email: string) => {
    if (
      checkEmail(email) ||
      email.length === 0
    ) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
    setUserEmail(email);
  }


  return (
    <ModalBase className={clsx("rounded-lg", className)} onOpenChange={toggleOpen} open={isOpen}>
      <div className="w-[532px] flex flex-col items-center">
        <span className="w-full flex items-start justify-end -mt-6 -mr-16">
          <IconButton className={`${THEME_DECRAFT && "text-white hover:text-accent transition-all"}`} onClick={toggleOpen} size={"small"}><CrossIcon size={THEME_DECRAFT ? 24 : 16} /></IconButton>
        </span>
        <div className="w-full flex flex-col items-center gap-3 -mt-2">
          <h1 className="w-full font-semibold text-lg">
            {renderFor === "edit"
              ? "Редактировать пользователя"
              : "Новый пользователь"}
          </h1>
          <div className="flex-1 w-full flex flex-col gap-6 pb-3">
            <div className="flex w-full gap-6">
              <span className="flex-1">
                <TextBoxDefault
                  placeholder="Введите данные"
                  error={userName === ""}
                  onChange={(e) => setUserName(e.target.value)}
                  value={userName}
                  label="Имя"
                />
              </span>
              <span className="flex-1">
                <TextBoxDefault
                  placeholder="Введите данные"
                  error={userLastName === ""}
                  onChange={(e) => setUserLastName(e.target.value)}
                  value={userLastName}
                  label="Фамилия"
                />
              </span>
            </div>
            <div className="flex w-full gap-6">
              <span className="flex-1">
                <TextBoxDefault
                  placeholder="Введите данные"
                  error={emailError}
                  onChange={(e) => onSetEmail(e.target.value)}
                  value={userEmail}
                  label="Email"
                  description="Некорректный e-mail"
                />
              </span>
            </div>
            {renderFor === "create" && <div className="flex w-full gap-6">
              <span className="flex-1">
                <Select
                  styleClasses={{ root: 'w-full', scroll: "max-h-[240px]" }}
                  searchable
                  label="Ваш город"
                  visibleCities={visibleCities}
                  items={citiesToShow}
                  setVisibleCities={setVisibleCities}
                  loadMore={onHandleLoadMoreCities}
                  value={userCityId}
                  onValueChange={(cityId: string) => setUserCityId(cityId)}
                  getItemTitle={(item) => item.name}
                  getItemValue={(item) => item.id}
                />
              </span>
              <span className="flex-1">
                <TextBoxDefault
                  placeholder="Введите данные"
                  error={userCompany === ""}
                  onChange={(e) => setUserCompany(e.target.value)}
                  value={userCompany}
                  label="Компания"
                />
              </span>
            </div>}
            {renderFor === "create" && <div className="flex w-full gap-6">
              <span className="flex-1">
                <TextBoxDefault
                  placeholder="Введите данные"
                  error={innError}
                  description="Некорректный ИНН"
                  onChange={(e) => onSetInn(e.target.value)}
                  value={userInn}
                  label="ИНН"
                />
              </span>
              <span className="flex-1">
                <Select
                  label="Квалификация"
                  placeholder="Выберите квалификацию"
                  value={userSpecialization}
                  onValueChange={(specId: string) => { setUserSpecialization(specId) }}
                  items={specializations || []}
                  getItemTitle={function (item: { name: string, id: string }): string {
                    return item.name;
                  }} getItemValue={function (item: { name: string, id: string }): string {
                    return item.id;
                  }} />
              </span>
            </div>}
            {renderFor === "create" && <span className="flex-1">
              <PasswordBox
                error={userPassword === ""}
                onChange={(e) => setUserPassword(e.target.value)}
                label="Пароль" />
            </span>}
          </div>
          <div className="flex gap-4 w-full">
            <Button
              disabled={!contuneAccess}
              onClick={onSendForm}
              className="rounded-lg w-auto"
              noFish
            >
              {renderFor === "edit" ? "Сохранить" : "Создать пользователя"}
            </Button>
            <Button onClick={toggleOpen} className="rounded-lg w-auto" noFish variant="secondary">
              Отмена
            </Button>
          </div>
        </div>
      </div>
    </ModalBase>
  );
};

export default CreateOrEditUserModal;
