
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logoBig.svg";
import AuthAdminForm from "../../Forms/AuthAdminForm";
import logoDecraft from "../../../assets/logoBigDecraft.svg"
import { THEME_DECRAFT } from "../../../assets/enums/Themes";


interface IProps {
	onAuth(): void;
}

const AuthAdminPage = (props: IProps) => {
	const navigate = useNavigate()

	return (
		<div className="flex overflow-y-auto h-full w-full flex-col items-center  pt-8 bg-gray-1">
			<div className="flex w-[500px] flex-col mb-5 items-center flex-1">
				<div className="flex mb-12 w-auto">
					<div className="pr-12 border-r-gray-2 border-r "><img onClick={() => navigate("/")} className="cursor-pointer" src={THEME_DECRAFT ? logoDecraft : logo} alt="" /></div>
					<div className="flex-1 pl-12">
						<p className="text-left text-gray-4">Биржа складских <br /> остатков</p>
					</div>
				</div>
				<div className="w-full flex-1 content-center pb-[30%]">
                <AuthAdminForm onAuth={props.onAuth} />
                </div>
			</div>
		</div>
	);
};

export default AuthAdminPage;
