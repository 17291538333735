import Typography from '../Typography';
import IconButton from '../buttons/IconButton';
import CrossIcon from '../../../assets/icons/CrossIcon';
import Select from './dropdown/Select';
import clsx from 'clsx';
import { THEME_DECRAFT } from '../../../assets/enums/Themes';


interface Props<T> {
  allItems: T[];
  items: T[]
  label?: string;
  placeholder?: string;
  selectWidth?: string;
  getItemTitle(item: T): string;
  getItemValue(item: T): string;
  getItemByValue(value: string): T;
  onSelectItem(item: T): void;
  onDeleteItem(item: T): void;
}

const ChipsBox = <T extends Object>(props: Props<T>) => {
  return (
    <div className='flex flex-col gap-1.5 '>
      {props.label && <Typography size='h6' className='text-gray-3'>{props.label}</Typography>}
      <div className='flex w-full items-center flex-wrap border border-gray-2 rounded-lg'>
        <div className='flex flex-wrap gap-1 p-1'>
          {props.items.map((item, index) => (
            <div key={index} className={clsx('flex items-center gap-2 p-0.5 pl-4 rounded-lg bg-accent-transparent',
              { "border border-neutral-content hover:text-accent hover:border-neutral active:bg-neutral": THEME_DECRAFT })}>
              <Typography size='h5'>{props.getItemTitle(item)}</Typography>
              <IconButton onClick={(e) => { e.stopPropagation(); props.onDeleteItem(item) }}>
                <CrossIcon size={18} />
              </IconButton>
            </div>
          ))}
        </div>
        <Select
          searchable
          noValue
          items={props.allItems.filter((it) => props.items.indexOf(it) === -1)}
          noLabel
          placeholder={props.placeholder}
          styleClasses={{
            trigger: '!border-0 text-gray-4',
            scroll: 'max-h-[120px]',
            selectWidth: props.selectWidth
          }}
          getItemTitle={props.getItemTitle}
          getItemValue={props.getItemValue}
          onValueChange={(value) => {
            let it = props.getItemByValue(value);
            props.onSelectItem(it)
          }}
        />
      </div>
    </div>
  )
}

export default ChipsBox;