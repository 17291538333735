import { FC, useEffect, useState } from "react";
import ModalBase from "../UI/modals/ModalBase";
import AuthForm from "../Forms/AuthForm";
import RegForm from "../Forms/RegForm";
import IconButton from "../UI/buttons/IconButton";
import CrossIcon from "../../assets/icons/CrossIcon";
import logo from "../../assets/logoBig.svg";
import logoDecraft from "../../assets/logoBigDecraft.svg"
import ScrollArea from "../UI/wrappers/ScrollArea";
import clsx from "clsx";
import { THEME_DECRAFT } from "../../assets/enums/Themes";

export enum EOpenFor {
    reg = "reg",
    auth = "auth",
}

interface IProps {
    openFor: EOpenFor | undefined
    toggleOpen: (open: boolean) => void;
    isMobile: boolean;
    onAuth: () => void;
    onOpenSupport: () => void;
    hidden: boolean
}

const AuthRegModal: FC<IProps> = (props: IProps) => {
    const [isRegForm, setIsRegForm] = useState<boolean>(props.openFor === EOpenFor.reg);

    useEffect(() => {
        setIsRegForm(props.openFor === EOpenFor.reg)
    }, [props.openFor])

    return (
        <ModalBase fromBottom={props.isMobile}
            onOpenChange={props.toggleOpen}
            open={!!props.openFor}
            className={clsx("w-screen sm:w-auto h-screen", props.isMobile && "!px-0 pt-4 gap-2 rounded-b-none", props.hidden && "hidden", THEME_DECRAFT && "!rounded-tl-[40px] !rounded-tr-none !rounded-bl-none")}>
            <div className="flex sm:w-[500px] flex-col items-center justify-start flex-1 overflow-hidden relative">
                <div className="w-full flex justify-end h-1 px-8 sm:px-4 py-4">
                    <IconButton onClick={() => { props.toggleOpen(false) }} className="-translate-y-4 translate-x-4" size="table">
                        <CrossIcon className={`${THEME_DECRAFT && "text-gray-3 hover:text-accent transition-all"}`}/>
                    </IconButton>
                </div>
                <div className="flex mb-6 w-full justify-center">
                    <div className="pr-4 sm:pr-12  border-r-gray-2 border-r "><img className="!h-8 sm:h-auto " src={THEME_DECRAFT ? logoDecraft : logo} alt="" /></div>
                    <div className="pl-4 sm:pl-12">
                        <p className="text-left text-gray-4 text-xs sm:text-sm">Биржа складских <br /> остатков</p>
                    </div>
                </div>
                <div className="flex-col items-center justify-start w-full overflow-hidden">
                    <div className="mb-[20px] flex flex-col w-full gap-4 px-4">
                        <div className="flex font-semibold select-none">
                            <p
                                onClick={() => setIsRegForm(false)}
                                className={`flex-1 w-[50%] ${!isRegForm ? "!text-accent" : "text-gray-4 hover:!text-accent transition-colors"} text-sm text-center cursor-pointer`}
                            >
                                Вход
                            </p>
                            <p
                                onClick={() => setIsRegForm(true)}
                                className={`flex-1 w-[50%] text-gray-4 ${isRegForm ? "!text-accent" : "text-gray-4 hover:!text-accent transition-colors"} text-sm text-center cursor-pointer`}
                            >
                                Регистрация
                            </p>
                        </div>
                        <div className="relative rounded-sm h-[2px] bg-gray-2">
                            <span
                                className={`${isRegForm ? "left-1/2" : "left-0"
                                    } absolute bottom-0 h-[2px] w-[50%] rounded-sm bg-accent duration-200`}
                            ></span>
                        </div>
                    </div>
                    <ScrollArea className="px-4 pb-10 bg-white">
                        <div className="w-full">
                            {!isRegForm ?
                                <AuthForm onOpenSupport={props.onOpenSupport} isMobile={props.isMobile} onAuth={props.onAuth} /> :
                                <RegForm onOpenSupport={props.onOpenSupport} isMobile={props.isMobile} onAuth={props.onAuth} />}
                        </div>
                    </ScrollArea>
                </div>
            </div>
            <div className="w-full flex flex-col items-center gap-2 sm:gap-4">
                <p className="text-gray-4 text-sm">Центр поддержки клиентов Systeme Electric:</p>
                <div className="flex flex-col sm:flex-row gap-1 sm:gap-4 font-semibold text-center">
                    <div className="flex gap-4">
                        <a href="tel:8 800 200 64 46">8 800 200 64 46</a>
                        <a href="tel:+7 495 777 99 88">+7 495 777 99 88</a>
                    </div>
                    <a href="mailto:support@systeme.ru">support@systeme.ru</a>
                </div>
            </div>
        </ModalBase>
    );
}

export default AuthRegModal;