import clsx from "clsx";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import IconButton from "../../../../../UI/buttons/IconButton";
import CrossIcon from "../../../../../../assets/icons/CrossIcon";
import Button from "../../../../../UI/buttons/Button";
import { IReference, UnllocatedReference } from "../../../../../../assets/interfaces/IReference";
import ScrollArea from "../../../../../UI/wrappers/ScrollArea";
import ReferenceCard from "../../../../../ReferenceCard";
import ArrowsIcon from "../../../../../../assets/icons/ArrowsIcon";
import ConfirmModal from "../../../../../modals/ConfirmModal";
import AllocationModal from "../../../../../modals/AllocationModal";
import { IScenario } from "../../../../../../assets/interfaces/IScenario";
import { IPartner } from "../../../../../../assets/interfaces/IPartner";
import { AutoDistribute } from "../../../../../../API/base/exchangeApi/exchangeFunctions";
import { ResponseContainer } from "../../../../../../API/BaseApi";
import { autoDistributeResponse } from "../../../../../../API/base/exchangeApi/exchangeInterfaces";
import { toast } from "react-toastify";
import LoaderModal from "../../../../../UI/loader/LoaderModal";
import InfoIcon from "../../../../../../assets/icons/InfoIcon";
import Tooltip from "../../../../../UI/Tooltip/Tooltip";
import { roundedCount } from "../../../utils/utils";

interface IProps {
    scenarioData: IScenario,
    basketReferences: IReference[],
    scenarioPartners: IPartner[],
    onSetScenario: (scenario: IScenario) => void
    setIsUnllocated: Dispatch<SetStateAction<boolean>>
    getUnllocatedReferences: (items: UnllocatedReference[]) => void;
}

const UnllocatedBar: FC<IProps> = ({ scenarioData, basketReferences, scenarioPartners, onSetScenario, setIsUnllocated, getUnllocatedReferences }) => {
    const [open, toggleOpen] = useState<boolean>(false)
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false)
    const [allocationRef, setAllocationRef] = useState<UnllocatedReference>()
    const [unllocatedRefs, setUnllocatedRefs] = useState<UnllocatedReference[]>([])
    const [unllocatedCount, setUnllocatedCount] = useState<number>(0)

    const [authoDistributeLoading, setAuthoDistributeLoading] = useState<boolean>(false)

    useEffect(() => {
        onSetUnllocatedRefs()
    }, [scenarioData, basketReferences])

    useEffect(() => {
        setUnllocatedCount(unllocatedRefs.length)
    }, [unllocatedRefs])

    useEffect(() => {
        setIsUnllocated(unllocatedCount > 0)
    }, [unllocatedCount])


    const onSetUnllocatedRefs = () => {
        const scenarioRefs = [] as IReference[];
        for (const basket of scenarioData.baskets) {
            for (const ref of basket.references) {
                const scenarioRefsArrIndex = scenarioRefs.findIndex((r) => r.referenceSku === ref.referenceSku)
                if (scenarioRefsArrIndex >= 0) {
                    //@ts-ignore
                    scenarioRefs[scenarioRefsArrIndex] = { ...scenarioRefs[scenarioRefsArrIndex], count: scenarioRefs[scenarioRefsArrIndex].count as number + ref.count }
                }
                else {
                    scenarioRefs.push(ref)
                }
            }
        }
        const unllocatedReferences: UnllocatedReference[] = []
        for (const ref of basketReferences) {
            const scenarioRefArrIndex = scenarioRefs.findIndex((r) => r.referenceSku === ref.referenceSku)
            if (scenarioRefArrIndex >= 0) {
                const refInScenario = scenarioRefs[scenarioRefArrIndex]
                const refInScenarioCount = refInScenario.count || 0
                if (ref?.count && refInScenarioCount >= 0) {
                    if (ref.count > refInScenarioCount) {
                        unllocatedReferences.push({ ...ref, unllocatedCount: ref.count - refInScenarioCount })
                    }
                }
            }
            else {
                if (ref.count as number > 0) {
                    unllocatedReferences.push({ ...ref, unllocatedCount: ref.count as number })
                }
            }
        }
        setUnllocatedRefs(unllocatedReferences)
        getUnllocatedReferences(unllocatedReferences)
    }


    const referencesList = unllocatedRefs.map((r: UnllocatedReference) => {
        return <div key={r.referenceSku} className="w-full border-b border-neutral flex flex-col">
            <div className="flex items-center">
                <span className="flex-1">
                    <ReferenceCard key={r.referenceSku} renderPlace={"unallocated"} data={r} />
                </span>
                <span className="h-6 min-w-6 px-1 rounded-[4px] text-secondary bg-neutral text-center">{r.unllocatedCount}</span>
            </div>
            <div className=" flex items-center">
                <Button
                    onClick={() => setAllocationRef(r)} noFish size="small"
                    disabled={scenarioPartners.length === 0}
                    variant="text" className="!w-[160px] !text-sm">
                    <ArrowsIcon size={20} />Распределить
                </Button>
                {scenarioPartners.length === 0 && <Tooltip side="top" text="В сценарии нет партнеров">
                    <span><InfoIcon className='text-system-yellow' size={18} /></span>
                </Tooltip>}
            </div>
        </div>
    })

    const onAutoDistribute = () => {
        const scenarioPartnersIds = scenarioPartners.map((p) => p.id)
        const basketRefsData = basketReferences.map((r) => {
            return { referenceSku: r.referenceSku, count: r.count as number }
        })
        setAuthoDistributeLoading(true)
        AutoDistribute({ partners: scenarioPartnersIds, references: basketRefsData }).then((r: ResponseContainer<autoDistributeResponse>) => {
            if (r.status !== "error" && r.data) {
                const newScenarioData = { ...scenarioData }
                newScenarioData.baskets = r.data.baskets.map((basket) => ({
                    ...basket, 
                    references: basket.references.map((ref) => ({
                        ...ref,
                        count: roundedCount(ref.count || 0, ref.multiple, ref.inStockCount)
                    }))
                }))
                onSetScenario(newScenarioData)
                toast.success("Референсы распределены")
            }
            else {
                toast.error("Ошибка автоматического распределения")
            }
            setAuthoDistributeLoading(false)
        })
    }
    const distributeButton = <Button onClick={onAutoDistribute}
        disabled={scenarioPartners.length === 0}
        size="small"
        className="rounded-lg w-auto"
        noFish>
        Распределить автоматически
    </Button>

    return (<div className={clsx("h-full bg-white flex flex-col w-[550px] shadow-3 absolute top-0 pt-5  transition-all", open ? "right-0" : "-right-[550px]")}>
        <div className="flex flex-col w-full h-full relative ">
            <div className="flex w-full items-center h-9 relative px-6 mb-4">
                <h1 className="flex-1 text-lg font-semibold ml-2">Нераспределенное</h1>
                <IconButton onClick={() => toggleOpen(false)} className="-mr-1 -mt-4" size="small"><CrossIcon size={18} /></IconButton>
            </div>
            <div className="flex-1 flex px-6 overflow-hidden items-center justify-center">
                {referencesList.length > 0 ? <ScrollArea className="pr-5">
                    <div className="pr-1 flex flex-col gap-4">
                        {referencesList}
                    </div>
                </ScrollArea> : <p className="text-base text-gray-3">Здесь ничего нет</p>}
            </div>
            {referencesList.length > 0 && <div className="h-16 border-t-gray-2 border-t px-6 flex items-center">
                <span className="flex-1 items-center justify-start flex gap-3">
                    {scenarioPartners.length === 0 ? <Tooltip side="top" text="В сценарии нет партнеров">
                        {distributeButton}

                    </Tooltip>
                        : distributeButton}
                </span>
                { //Предварительно вырезанный функционал
                    // <Button onClick={() => setConfirmDeleteModal(true)} size="small" variant="tetriary" noFish className="w-auto rounded-lg text-gray-3 hover:text-accent"><TrashIcon size={18} /> Удалить</Button>
                }
            </div>}
            {!open && <div
                onClick={() => toggleOpen(true)}
                className="bg-white w-[190px] h-9 absolute top-[calc(50%-70px)]
                 -left-[113px] -rotate-90 rounded-t-lg cursor-pointer flex items-center px-2 shadow-4 gap-2 hover:bg-accent-lite transition-all justify-center">
                <p className="text-sm font-semibold">Нераспределенное</p>
                {unllocatedCount > 0 && <span className=" rounded-[50px] h-5 w-5 bg-secondary-content text-white rotate-90 text-sm text-center">{unllocatedCount}</span>}
            </div>}
        </div>
        { //Предварительно вырезанный функционал
            <ConfirmModal
                funk={() => { }}
                confirmBtnText="Удалить"
                headText="Удалить референсы?"
                text="Вы уверены, что хотите полностью удалить список нераспределенных референсов?"
                isOpen={confirmDeleteModal}
                toggleOpen={() => setConfirmDeleteModal(!confirmDeleteModal)} />
        }
        {typeof (allocationRef) === "object" && <AllocationModal
            scenarioData={scenarioData}
            scenarioPartners={scenarioPartners}
            isOpen={typeof (allocationRef) === "object"}
            toggleOpen={() => setAllocationRef(undefined)}
            refSku={allocationRef?.referenceSku || ""}
            unllocatedCount={allocationRef?.unllocatedCount as number}
            inBasketCount={basketReferences.find((r) => r.referenceSku === allocationRef.referenceSku)?.count as number}
            saveFunk={onSetScenario}
            basketReferences={basketReferences}
        />}
        <LoaderModal open={authoDistributeLoading} />
    </div>);
}

export default UnllocatedBar;