import { ISvg } from "../interfaces/ISvg";

function DecraftUserIcon({size, className}: ISvg) {
    return (
        <svg width={size} height={size} className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 10C0 4.47715 4.47715 0 10 0H32V22C32 27.5228 27.5228 32 22 32H0V10Z" fill="#159F6F" />
            <path d="M15.7977 15.8964C14.3786 15.8964 13.2241 14.7419 13.2241 13.3228C13.2241 11.9038 14.3786 10.7493 15.7977 10.7493C17.2167 10.7493 18.3713 11.9038 18.3713 13.3228C18.3713 14.7419 17.2167 15.8964 15.7977 15.8964ZM15.7977 12.0871C15.1159 12.0871 14.561 12.642 14.561 13.3238C14.561 14.0056 15.1159 14.5604 15.7977 14.5604C16.4795 14.5604 17.0343 14.0056 17.0343 13.3238C17.0343 12.642 16.4795 12.0871 15.7977 12.0871Z" fill="white" />
            <path d="M20.0628 19.4919H11.5361V18.8234C11.5361 16.4723 13.4489 14.5596 15.8 14.5596C18.151 14.5596 20.0638 16.4723 20.0638 18.8234V19.4919H20.0628ZM12.9504 18.1549H18.6495C18.3468 16.8619 17.1837 15.8965 15.8 15.8965C14.4162 15.8965 13.2531 16.8619 12.9504 18.1549Z" fill="white" />
            <path d="M23.1406 12.0279C22.475 11.8264 21.8046 12.3249 21.8056 13.0201C21.8065 13.9836 21.8084 15.2719 21.8084 16.4951C21.8084 18.6906 20.7962 20.483 18.8004 21.8228C17.5255 22.6784 16.2258 23.0776 15.799 23.1941C15.3712 23.0776 14.0715 22.6784 12.7976 21.8228C10.8018 20.4839 9.78952 18.6915 9.78952 16.4951C9.78952 14.4325 9.79142 13.5635 9.79333 12.9523C9.79524 12.2227 9.7962 11.8551 9.79142 10.2422L15.7999 8.42492L23.1435 10.6299C23.1435 10.6117 23.1435 10.5888 23.1435 10.563C23.1454 9.7838 22.6364 9.09624 21.8906 8.86992L15.799 7.02783L8.45068 9.2519L8.45259 9.74942C8.45928 11.7892 8.45832 12.153 8.45641 12.9494C8.4545 13.5616 8.45259 14.4306 8.45259 16.4951C8.45259 19.1413 9.71025 21.3759 12.09 22.9573C13.8423 24.1223 15.5803 24.5195 15.6538 24.5358L15.799 24.5682L15.9441 24.5358C16.0177 24.5195 17.7547 24.1223 19.508 22.9573C21.8877 21.3749 23.1454 19.1403 23.1454 16.4951C23.1454 14.4315 23.1406 12.3621 23.1406 12.0279Z" fill="white" />
        </svg>

    );
};

export default DecraftUserIcon;