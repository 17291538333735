export const scrollListTop = (listId: string) => {
	document.getElementById(`${listId}`)?.scrollTo(0, 0);
}

export const getRandomInt = (min: number, max: number) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min) + min);
};

export const getRandomId = (prefix: string) => {
	return prefix + getRandomInt(10000, 100000);
};

export const getRefSum = (count: number, price: number): number => {
	return Math.round(count * price * 100) / 100
}

export const formatPriceToStr = (price: number): string => {
	return Number.isInteger(price) ? price.toString() : price.toFixed(2);
}