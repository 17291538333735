import { ISvg } from "../interfaces/ISvg";

function TrackerIcon({ size = 24, className }: ISvg) {
    return (
        <svg width={size} height={size} className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 14.6663C8 14.6663 13 10.6663 13 6.33301C13 3.57157 10.7614 1.33301 8 1.33301C5.23857 1.33301 3 3.57157 3 6.33301C3 10.6663 8 14.6663 8 14.6663Z" stroke="currentColor" stroke-linejoin="round" />
            <path d="M8 8.33301C9.10457 8.33301 10 7.43757 10 6.33301C10 5.22844 9.10457 4.33301 8 4.33301C6.89543 4.33301 6 5.22844 6 6.33301C6 7.43757 6.89543 8.33301 8 8.33301Z" stroke="#969FA8" stroke-linejoin="round" />
        </svg>
    );
}

export default TrackerIcon;