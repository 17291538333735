import * as LabelBase from "@radix-ui/react-label";
import clsx from "clsx";
import { forwardRef, TextareaHTMLAttributes } from "react";

import { ISvg } from "../../../../assets/interfaces/ISvg";
import { getRandomId } from "../../../../utils/reusedFunks";


function AreaHandleIcon({ size = 24, className }: ISvg) {
	return (
		<svg
			className={className}
			fill="none"
			height={size}
			viewBox="0 0 12 12"
			width={size}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M5 11L11 5" stroke="#D0D2D2" />
			<path d="M1 11L11 1" stroke="#D0D2D2" />
		</svg>
	);
}

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	label?: string;
	error?: boolean;
}

const TextArea = forwardRef<HTMLDivElement, Props>(
	({ label, error, className, ...props }: Props, ref) => {
		const id = getRandomId("tb");
		return (
			<div ref={ref} className="flex flex-col gap-1">
				<LabelBase.Root className="block h-4 text-xs text-gray-4" htmlFor={id}>
					{label}
				</LabelBase.Root>
				<div className="relative flex">
					<textarea
						className={clsx(
							"min-h-[3rem] w-full rounded-lg border border-gray-2 px-3 py-2 text-sm outline-none disabled:bg-gray-1",
							error && "border-error",
							className,
						)}
						id={id}
						{...props}
					/>
					<AreaHandleIcon
						className="pointer-events-none absolute bottom-0.5 right-0.5"
						size={10}
					/>
				</div>
			</div>
		);
	},
);

TextArea.displayName = "TextArea";

export default TextArea;
