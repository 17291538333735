import { ReactNode, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logoBig.svg"
import logoDecraft from '../../assets/logoBigDecraft.svg'
import AuthorizedRoutes from "../../routes/routes";
import DropdownMenu from "../UI/inputs/dropdown/DropdownMenu";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import UserIcon from "../../assets/icons/UserIcon";
import LogOutIcon from "../../assets/icons/LogOutIcon";
import { getProfileResponse } from "../../API/base/profileApi/profileInterfaces";
import HistoryIcon from "../../assets/icons/HistoryIcon";
import Button from "../UI/buttons/Button";
import IconButton from "../UI/buttons/IconButton";
import { EOpenFor } from "../modals/AuthRegModal";
import { THEME_DECRAFT } from "../../assets/enums/Themes";
import DecraftUserIcon from "../../assets/icons/DecraftUserIcon";
import TrackerIcon from "../../assets/icons/TrackerIcon";
import { PaylodParam } from "../../API/base/payloadApi/payloadInterfaces";

interface IProps {
	profileInfo?: getProfileResponse
	isMobile?: boolean;
	cityList?: PaylodParam[]
	tempCityId?: string

	onCityChangeOpen?: (value: boolean) => void;
	onLogout?: () => void;
	onOpenAuth?: (openFor: EOpenFor) => void;
}

enum headerHeight {
	default = "min-h-[52px]",
	helloPage = "min-h-[80px]"
}

const themeUserIcon: Record<string, ReactNode> = {
	defaultIcon: <UserIcon size={18} className="text-gray-3" />,
	decraftIcon: <DecraftUserIcon size={32} className="text-gray-3" />
}

const Header = (props: IProps) => {
	const [menu, setMenu] = useState<boolean>(false);
	const toggleMenu = () => {
		setMenu(!menu)
	}
	const navigate = useNavigate();
	const location = useLocation();
	const isHelloPage = location.pathname === "/"
	const [popupOpen, setPopupOpen] = useState<boolean>(!!sessionStorage.getItem('city') || props.profileInfo?.cityId === props.profileInfo?.locationCityId)
	const toOrderHistory = <div onClick={() => {
		navigate(AuthorizedRoutes.OrderHistory, { state: { prevPath: "check" } })
	}} className="flex items-center gap-2 w-44">
		<HistoryIcon size={18} className="text-accent" />
		<p className="font-medium">История заказов</p>
	</div>
	const toProfileOption = <div onClick={() => {
		navigate(AuthorizedRoutes.Profile, { state: { prevPath: "check" } })
	}} className="flex items-center gap-2 w-44">
		<UserIcon size={18} className="text-accent" />
		<p className="font-medium">Профиль</p>
	</div>

	const exitOption = <div onClick={props.onLogout} className="flex items-center gap-2 w-full">
		<LogOutIcon size={18} className="text-error" />
		<p className="font-medium">Выйти</p>
	</div>

	return (
		<header className={`flex w-full justify-end items-center bg-gray-1 px-4 sm:px-9 ${isHelloPage ? headerHeight.helloPage : headerHeight.default}`}>
			<div className="flex flex-1 justify-start items-center h-8">
				<img onClick={() => navigate("/")} src={THEME_DECRAFT ? logoDecraft : logo} alt="logo" className="h-6 sm:h-full pr-4 sm:pr-5 cursor-pointer" />
				<div className="text-gray-3 font-semibold border-l border-gray-2 pl-4 sm:pl-5 min-w-[260px] h-8 text-xs sm:text-sm leading-4">
					Биржа складских <br /> остатков
				</div>
			</div>
			{props.profileInfo ? <div className="flex flex-1 justify-end items-center gap-8">
				<div className="flex relative">
					<div onClick={() => props.onCityChangeOpen && props.onCityChangeOpen(true)} className="flex gap-2 items-center cursor-pointer">
						<TrackerIcon className="text-gray-3" />
						{props.cityList?.find((el) => el.id === props.tempCityId)?.name}
					</div>
					{!popupOpen && <div className="w-[237px] bg-white rounded-[8px] shadow-1 p-5 flex flex-col gap-6 absolute right-0 top-7 z-[1]">
						<span className="text-[18px] font-semibold">Это ваш город?</span>
						<div className="flex gap-3">
							<Button onClick={
								() => {
									sessionStorage.setItem('city', 'true')
									setPopupOpen(prev => !prev)
								}
							}
								className="w-[53px] rounded-[8px] text-[16px] font-medium">
								Да
							</Button>
							<Button
								onClick={() => props.onCityChangeOpen && props.onCityChangeOpen(true)}
								variant="secondary"
								className="w-[112px] text-[16px] font-medium rounded-[8px]">
								Изменить
							</Button>
						</div>

					</div>}
				</div>
				<DropdownMenu onOpenChange={() => toggleMenu()} items={[toOrderHistory, toProfileOption, exitOption]}>
					<div className="flex items-center gap-3">
						{THEME_DECRAFT ? themeUserIcon.decraftIcon : themeUserIcon.defaultIcon}
						<div className="flex flex-col text-sm leading-4">
							<p className="font-medium">{`${props.profileInfo?.firstName} ${props.profileInfo?.lastName}`}</p>
							<p className="text-gray-4">{props.profileInfo?.companyName}</p>
						</div>
						<div className="h-[24px] border-[1px] border-gray-2 mx-[10px]" />
						<ArrowRightIcon className={`text-${!menu ? "gray-3" : "accent"} hover:text-accent ${!menu ? "rotate-90" : "-rotate-90"}  transition-all`} size={20} />
					</div>
				</DropdownMenu>
			</div> :
				<div>
					{!props.isMobile ? <div className="flex items-center gap-3">
						<Button onClick={() => props.onOpenAuth && props.onOpenAuth(EOpenFor.auth)} size="small" className="rounded-lg !border !border-accent hover:!text-accent-dark hover:!border-accent-dark" noFish variant="tetriary">Войти</Button>
						<Button onClick={() => props.onOpenAuth && props.onOpenAuth(EOpenFor.reg)} size="small" className="rounded-lg" noFish>Зарегистрироваться</Button>
					</div> :
						<IconButton onClick={() => props.onOpenAuth && props.onOpenAuth(EOpenFor.auth)} size="small" className="!bg-accent">
							<span className="text-white ">
								<LogOutIcon size={20} />
							</span>
						</IconButton>
					}
				</div>}
		</header>
	);
};

export default Header;
