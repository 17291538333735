import { FC, ReactNode, useEffect, useState } from "react";
import clsx from "clsx";
import Button from "../../../UI/buttons/Button";
import ScrollArea from "../../../UI/wrappers/ScrollArea";
import laptopCutMobile from "../../../../assets/images/laptopCutMobile.png"
import laptopCut from "../../../../assets/images/laptopCut.png"
import laptopCutDecraft from "../../../../assets/images/laptopCutDecraft.png"
import laptopCutDecraftMobile from "../../../../assets/images/laptopCutDecraftMobile.png"
import LendingFooter from "../../../layout/LendingFooter";
import StepsSmall from "./components/StepsSmall";
import StepsBig from "./components/StepsBig";
import AuthRegModal, { EOpenFor } from "../../../modals/AuthRegModal";
import Header from "../../../layout/Header";
import EmailErrorAuthModal from "../../../modals/EmailErrorAuthModal";
import CookieModal from "../../../modals/CookieModal";
import { THEME_DECRAFT } from "../../../../assets/enums/Themes";
import LendingFooterDecraft from "../../../layout/LendingFooterDecraft";

interface IProps {
	onAuth(): void;
}


const HelloPage: FC<IProps> = (props: IProps) => {
	const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
	const [modalOpenFor, setModalOpenFor] = useState<EOpenFor>()
	const [supportModal, setSupportModal] = useState<boolean>(false)
	const [isMobile, setIsMobile] = useState<boolean>(windowWidth < 768)
	const [cookieModalShow, setCookieModalShow] = useState<boolean>(false)

	const themeIntroImg: { decraft: ReactNode, default: ReactNode } = {
		decraft: <img src={isMobile ? laptopCutDecraftMobile : laptopCutDecraft} className="max-h-full max-w-full object-contain" />,
		default: <img src={isMobile ? laptopCutMobile : laptopCut} className="max-h-full max-w-full object-contain" />
	}

	useEffect(() => {
		const cookieAccepted = localStorage.getItem("cookieAccepted");
		if (!isMobile && !cookieAccepted) {
			setCookieModalShow(true);
		}
	}, []);

	const toggleCookieModal = (accept: boolean) => {
		try {
			if (accept) {
				localStorage.setItem("cookieAccepted", JSON.stringify(true));
			}
			setCookieModalShow(false);
		} catch (error) {
			console.error('Ошибка при сохранении настроек cookies:', error);
			setCookieModalShow(false);
		}
	};

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
			setIsMobile(window.innerWidth < 768)
		}
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div className={clsx("flex overflow-hidden h-[100%] w-full flex-col items-centerbg-white")}>
			<Header onOpenAuth={setModalOpenFor} isMobile={isMobile} />
			<div className="overflow-hidden h-[calc(100%_-_52px)]">
				<ScrollArea>
					<div className="flex flex-col p-3 gap-6 sm:p-6">
						<div className="w-full bg-gray-1 xl:rounded-[32px] sm:rounded-[24px] rounded-[16px] pt-6 sm:pt-8 flex flex-col overflow-hidden items-center">
							<div className="w-full flex flex-col items-center gap-10 sm:gap-16">
								<div className="flex flex-col gap-3">
									<h1 className="text-accent font-semibold text-3xl text-center sm:text-5xl">Биржа складских остатков</h1>
									<p className="text-gray-3 text-center text-sm sm:text-base">
										Сервис для оптовой закупки оборудования <br /> Systeme Electric у дистрибьюторов
									</p>
								</div>
								<div className="w-full pr-[30%] sm:w-[50%] sm:pr-0">
									<StepsSmall isMobile={isMobile} />
								</div>
								<Button onClick={() => setModalOpenFor(EOpenFor.reg)} className="rounded-lg w-fit mb-10" noFish>Зарегистрироваться</Button>
							</div>
							<div className="flex justify-center items-end flex-1 w-full overflow-hidden">
								{THEME_DECRAFT ? themeIntroImg.decraft : themeIntroImg.default}
							</div>
						</div>
						<StepsBig />
					</div>
					{THEME_DECRAFT ? <LendingFooterDecraft isMobile={isMobile} /> : <LendingFooter isMobile={isMobile} />}
				</ScrollArea>
			</div>
			{!!modalOpenFor &&
				<AuthRegModal
					hidden={supportModal}
					onOpenSupport={() => setSupportModal(true)}
					isMobile={isMobile}
					openFor={modalOpenFor || EOpenFor.reg}
					toggleOpen={() => setModalOpenFor(undefined)}
					onAuth={props.onAuth} />}
			{supportModal && <EmailErrorAuthModal
				isMobile={isMobile}
				onOpenChange={setSupportModal}
				open={supportModal}
			/>}
			<CookieModal isOpen={cookieModalShow} toggleOpen={toggleCookieModal} />
		</div>
	);
};

export default HelloPage;
