import { ISvg } from "../interfaces/ISvg";

function ArrowRightUpIcon({ size = 24, className }: ISvg) {
    return (
        <svg width={size} height={size} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 18L18 6" stroke="#D6F3E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.25 6H18V15.75" stroke="#D6F3E9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default ArrowRightUpIcon;