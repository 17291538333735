import clsx from "clsx";
import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from "react";
import { THEME_DECRAFT } from "../../../assets/enums/Themes";

type buttonType = "primary" | "secondary";
type buttonSize = "big" | "small" | "table";

const typeClasses = {
	primary:
		`text-primary border-transparent bg-transparent ${!THEME_DECRAFT && "enabled:hover:bg-accent/10"} enabled:hover:text-accent-content disabled:text-gray-2  active:text-accent`,
	secondary: "text-primary border-transparent bg-neutral enabled:hover:bg-accent/10 enabled:hover:text-accent disabled:text-gray-2 active:text-accent",
};

const sizeClasses = {
	big: "w-9 h-9",
	small: "w-7 h-7",
	table: "w-4 h-4"
};

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: ReactNode;

	size?: buttonSize;
	variant?: buttonType;
}

const IconButton = forwardRef<HTMLButtonElement, Props>(
	({ size = "big", variant = "primary", children, className, ...props }: Props, ref) => {
		return (
			<button
				ref={ref}
				className={clsx(
					"flex cursor-pointer items-center justify-center rounded-lg text-center", 
					THEME_DECRAFT && variant === "secondary" && "!rounded-[4px]",
					"outline-none transition-all disabled:cursor-default",
					sizeClasses[size],
					typeClasses[variant],
					className,
				)}
				{...props}
			>
				{children}
			</button>
		);
	},
);

IconButton.displayName = "IconButton";

export default IconButton;
