import clsx from "clsx";
import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from "react";
import FishIcon from "../../../assets/icons/FishIcon";
import { THEME_DECRAFT } from "../../../assets/enums/Themes";

type buttonType = "primary" | "secondary" | "tetriary" | "text" | "warning";
type buttonSize = "big" | "small"
type buttonRound = "big" | "small"

const typeClasses = {
  primary:
    "bg-accent text-white border-accent enabled:hover:bg-accent-content disabled:opacity-30 active:bg-accent-content",
  secondary:
    "text-accent border-base-300 enabled:hover:bg-neutral disabled:text-neutral active:bg-neutral",
  tetriary:
    "text-accent bg-transparent border-base-300 enabled:hover:border-neutral disabled:text-neutral active:border-neutral",
  text: "text-accent border-transparent bg-transparent enabled:hover:text-accent-content disabled:text-neutral active:text-accent-content",
  warning:
    "bg-error text-white border-error enabled:hover:bg-error-hover disabled:bg-neutral disabled:border-neutral active:bg-error-hover",
};

const sizeClasses = {
  big: "px-6 h-12",
  small: "px-4 h-9",
};

const roundedClasses = {
  big: "rounded-[32px]",
  small: "rounded-[8px]",
};

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  rounded?: buttonRound;
  size?: buttonSize;
  variant?: buttonType;
  noFish?: boolean
}

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ size = "big", variant = "primary", rounded = "big", children, noFish, className, ...props }: Props, ref) => {
    const decraftSize: buttonSize = THEME_DECRAFT && variant !== "text" ? "big" : size
    return (
      <button
        ref={ref}
        className={clsx(
          "flex cursor-pointer w-[100%] items-center justify-center gap-1 whitespace-nowrap rounded-[32px] border text-center text-sm font-semibold",
          "overflow-ellipsis outline-none transition-all disabled:cursor-default select-none",
          sizeClasses[decraftSize],
          typeClasses[variant],
          roundedClasses[rounded],
          className,
        )}
        {...props}
      >
        {!noFish && <FishIcon size={20} />}
        {children}
        {!noFish && <FishIcon size={20} />}
      </button>
    );
  },
);

Button.displayName = "Button";

export default Button;
