import * as LabelBase from '@radix-ui/react-label';
import clsx from 'clsx';
import CustomRadioBase, { RadioProps } from './CustomRadioBase';
import { getRandomId } from '../../../utils/reusedFunks';

interface Props<T> extends RadioProps<T> {
	label?: string;
	error?: boolean;
}

const CustomRadioGroup = <T extends object>(props: Props<T>) => {
	const id = getRandomId('rg');
	return (
		<div className='flex max-w-fit flex-col gap-1'>
			<LabelBase.Root className='block h-4 text-xs text-gray-4' htmlFor={id}>
				{props.label}
			</LabelBase.Root>
			<CustomRadioBase
				key={id}
				items={props.items}
				value={props.value}
				getItemTitle={props.getItemTitle}
				getItemValue={props.getItemValue}
				getItemDisabled={props.getItemDisabled}
				getItemTooltip={props.getItemTooltip}
				onValueChange={props.onValueChange}
				styleClasses={{
					root: clsx('flex rounded-xl text-sm font-medium bg-gray-1 p-1 gap-[9px]', props.error && 'border !border-error', props.styleClasses?.root),
					item: clsx(
						'h-10 px-5 transition-all relative text-gray-4 rounded-lg',
						'data-[state=checked]:bg-white data-[state=checked]:text-black',
						'after:absolute after:w-[1px] last:after:hidden after:h-4 after:bg-gray-2',
						'after:top-1/2 after:-translate-y-1/2 after:-right-[5px] after:cursor-default',
						props.styleClasses?.item
					),
				}}
			/>
		</div>
	);
};

export default CustomRadioGroup;
