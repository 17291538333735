import { FC, useRef, useState } from "react";
import Button from "../../../UI/buttons/Button";
import ImportIcon from "../../../../assets/icons/ImportIcon";
import DownloadIcon from "../../../../assets/icons/DownloadIcon";
import ShablonIcon from "../../../../assets/icons/ShablonIcon";
import { DownloadMasterDB, DownloadStock, UploadCostReferences, UploadMasterDB } from "../../../../API/admin/dbProductApi/dbProductFunctions";
import { toast } from "react-toastify";
import LoaderModal from "../../../UI/loader/LoaderModal";
import TextBox from "../../../UI/inputs/textInput/TextBox";

interface IProps { }

const DBProduct: FC<IProps> = ({ }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [fileLoaderKey, setFileLoaderKey] = useState<number>(0)
  const [link, setLink] = useState<string>('')

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const onUpdateMasterDB = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setLoading(true)
      UploadMasterDB({ file }).then((response) => {
        if (response.status === "error") {
          toast.error((response.message && response.message.length <= 300) ? response.message : "Ошибка обновления мастер БД")

        }
        else {
          toast.success("Мастер БД обновлен")
        }
        setLoading(false)
      });
    }
    setFileLoaderKey((prev) => prev + 1)
  };

  const onUploadCostReferences = async (link: string) => {
    setLoading(true)
    const response = await UploadCostReferences({ link })
    if (response.status !== 'error') {
      toast.success('Стоимость референсов обновлена')
    } else {
      toast.error(response.message)
    }
    setLoading(false)
  }

  const onHandleInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") link && onUploadCostReferences(link)
  }

  const onDownloadStock = async () => {
    setLoading(true)
    await DownloadStock((msg: string) => toast.error(msg || "Ошибка загрузки файла"))
    setLoading(false)
  };

  const onDownloadMasterDB = async () => {
    setLoading(true)
    await DownloadMasterDB((msg: string) => toast.error(msg || "Ошибка загрузки файла"))
    setLoading(false)
  };

  return (
    <div className="p-16">
      <div className="flex gap-4 flex-col items-center justify-center">
        <Button noFish className="w-[394px]" onClick={() => fileInputRef.current?.click()}>
          <ImportIcon size={20} />
          Обновить мастер БД
        </Button>
        <Button noFish className="w-[394px]" onClick={onDownloadStock}>
          <DownloadIcon size={20} />
          Скачать последнюю версию остатков
        </Button>
        <Button noFish className="w-[394px]" onClick={onDownloadMasterDB}>
          <ShablonIcon size={20} />
          Скачать последнюю версию мастер БД
        </Button>
        <TextBox
          value={link}
          onKeyDown={onHandleInput}
          onChange={(e) => {
            const value = e.target.value
            setLink(value)
          }}
          className="!w-[394px]"
          label="Ссылка на таблицу стоимости референсов"
        />
      </div>
      <input
        key={fileLoaderKey}
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={onUpdateMasterDB}
        accept=".csv, .xlsx"
      />
      <LoaderModal open={loading} />
    </div>
  );
};

export default DBProduct;
