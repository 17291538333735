import { FC, useEffect, useState } from "react";
import { IReference } from "../../../assets/interfaces/IReference";
import IconButton from "../buttons/IconButton";
import ArrowRightIcon from "../../../assets/icons/ArrowRightIcon";
import TrashIcon from "../../../assets/icons/TrashIcon";
import clsx from "clsx";
import Counter from "../inputs/textInput/Counter";
import systemeIcon from "../../../assets/systemeIcon.svg"
import { THEME_DECRAFT } from "../../../assets/enums/Themes";
import { roundedCount } from "../../pages/basicPages/utils/utils";
import { toast } from "react-toastify";

interface IProps {
  edit?: boolean;
  references: IReference[];
  companyName: string;
  companyAvatar: string,
  onDeleteBasket?: (companyName: string) => void
  onChangeBasketReferences?: (companyName: string, references: IReference[]) => void
  noBorder?: boolean
  noCount?: boolean
}

enum themeTextStyle {
  default = "text-white",
  decraft = "text-accent"
}

const ReferenceTable: FC<IProps> = ({ companyName, references, edit, companyAvatar, onDeleteBasket, onChangeBasketReferences, noBorder, noCount }) => {
  const [completeness, setCompleteness] = useState({ full: 0, partly: 0 })
  const [tableOpen, toggleTableOpen] = useState(!edit ? false : true);
  useEffect(() => {
    if (references) {
      onSetCompletness()
    }
  }, [references])

  const onSetCompletness = () => {
    let full = 0;
    let partly = 0;
    if (references.length > 0) {
      references.forEach((i: IReference) => {
        if (i.count && i.inStockCount && i.count <= i.inStockCount) full += 1; else partly += 1;
      })
    }
    setCompleteness({ full: full, partly: partly })
  }

  const onDeleteReference = (sku: string) => {
    if (edit && onChangeBasketReferences) {
      const newRefences: IReference[] = references.filter((r) => r.referenceSku !== sku)
      onChangeBasketReferences(companyName, newRefences)
    }
  }

  const onChangeRefCount = (sku: string, count: number, handleInput: boolean = false, roundedUp?: boolean) => {
    if (!edit || !onChangeBasketReferences) return;
    const newRefences: IReference[] = [...references];
    const currentRefIndex = newRefences.findIndex((r) => r.referenceSku === sku);
    const currentRef = { ...newRefences[currentRefIndex] };
    const multiple = currentRef.multiple || 0;
    const stock = currentRef.inStockCount
    currentRef.count = roundedCount(count, multiple, stock, roundedUp);
    newRefences[currentRefIndex] = currentRef;
    onChangeBasketReferences(companyName, newRefences);

    if (handleInput && multiple > 1) toast.success(`Кратность упаковки товара ${multiple}`)
  };

  const referencesList = references.map((r: IReference) => {
    const onChangeCount = (count: number, handleInput: boolean = false, roundedUp: boolean = true) => {
      onChangeRefCount(r.referenceSku, count, handleInput, roundedUp)
    }

    const overMax = (r.count || 0) + (r.multiple || 0) > (r.inStockCount || 0)

    return <tr key={r.referenceSku}>
      <td className={clsx("border border-gray-1 p-2 transition-all", r.count === 0 && "bg-gray-1")}>
        <div className={clsx("text-xs", r.count === 0 && "line-through")}>{r.referenceSku}</div>
        {!noCount && (
          <div className="text-gray-4 text-xs">в наличии: {r.inStockCount}</div>
        )}

      </td>
      <td className={clsx("border align-top border-gray-1 p-2 text-xs font-normal", r.count === 0 && "line-through bg-gray-1")}>
        {r.desc}
      </td>
      <td className={clsx("border w-[123px] border-gray-1 px-2", !edit && "align-top")}>
        {edit ?
          <div className="flex items-center justify-center">
            <Counter
              maxValue={r.inStockCount}
              disableIncrement={overMax}
              setNumber={onChangeCount}
              value={r.count as number} size="small" />
          </div>
          :
          <div className="py-2 text-xs font-normal ">{r.count}</div>
        }
      </td>
      {edit && <td className="border border-gray-1 px-2 w-[32px]">
        <IconButton onClick={() => onDeleteReference(r.referenceSku)} size="small"><TrashIcon className={`${THEME_DECRAFT && "text-gray-3 hover:text-accent transition-all"}`} size={16} /></IconButton>
      </td>}
    </tr>
  })

  return (<div className="w-full flex flex-col gap-4">
    <div className={`flex w-full items-center ${!noBorder && 'border-gray-1 border-b pb-4'}`}>
      <div className="flex flex-1 gap-2 items-center">
        <img className="w-6 h-6 select-none" src={companyAvatar || systemeIcon} alt="IMG" />
        <p className="text-base font-medium">{companyName}</p>
        {!edit ? <div className="flex flex-1 gap-2 items-center">
          {completeness.full > 0 && <span className={`flex items-center justify-center w-[22px] h-[22px] rounded-[50%] select-none
                  text-xs font-semibold bg-primary-content ${THEME_DECRAFT ? themeTextStyle.decraft : themeTextStyle.default}`}>{completeness.full}</span>}
          {completeness.partly > 0 && <span className="-ml-1 flex items-center justify-center w-[22px] h-[22px] rounded-[50%] select-none
                 text-white text-xs font-semibold bg-neutral-content">{completeness.partly}</span>}
        </div> : <div className="flex h-full items-center -ml-[6px]">
          <IconButton onClick={() => onDeleteBasket && onDeleteBasket(companyName)} size="small"><TrashIcon size={16} /></IconButton>
        </div>}
      </div>
      {!edit && <span className="flex items-center h-full">
        <IconButton size="small" onClick={() => toggleTableOpen(!tableOpen)}><ArrowRightIcon className={`transition-all text-gray-3 ${!tableOpen ? "rotate-90" : "-rotate-90"}`} size={20} /></IconButton>
      </span>}
    </div>
    {tableOpen && <div className="w-full">
      <table className="w-full">
        <thead>
          <tr className="select-none">
            <th className="font-semibold text-left text-gray-4 text-xs pb-2">
              Референс
            </th>
            <th className="font-semibold text-left text-gray-4 text-xs pb-2">
              Название
            </th>
            <th className="font-semibold text-left text-gray-4 text-xs pb-2">
              Количество
            </th>
          </tr>
        </thead>
        <tbody>
          {referencesList}
        </tbody>
      </table>
    </div>}
  </div>);
}

export default ReferenceTable;