import { FC, useEffect, useState } from "react";
import PlusIcon from "../../../../assets/icons/PlusIcon";
import MinusIcon from "../../../../assets/icons/MinusIcon";
import TextBox from "./TextBox";
import IconButton from "../../buttons/IconButton";
import clsx from "clsx";
import { THEME_DECRAFT } from "../../../../assets/enums/Themes";

interface IProps {
  value: number,
  size: sizeType,
  disabled?: boolean,
  maxValue?: number,
  minValue?: number,
  disableIncrement?: boolean

  setNumber: (count: number, handleInput?: boolean, roundedUp?: boolean) => void;
}

enum themeRoundedStyles {
  default = "rounded-lg",
  decraft = "!rounded-full"
}

type sizeType = "big" | "small" | "full" | "decraftSize"

const sizes = {
  big: "!h-8",
  small: "!h-6",
  full: '!h-full',
  decraftSize: "!h-[24px]",
};

const rounded = THEME_DECRAFT ? themeRoundedStyles.decraft : themeRoundedStyles.default

const iconSizes = {
  big: "!w-8",
  small: "!w-6",
  full: "!w-full",
  decraftSize: "!w-[24px]",
};

const Counter: FC<IProps> = ({
  setNumber,
  value,
  size,
  disabled,
  maxValue,
  minValue,
  disableIncrement,
}) => {
  const [tempValue, setTempValue] = useState<number | null>(value)

  const forcedSize: sizeType = THEME_DECRAFT ? "decraftSize" : size

  const handleDecrement = () => {
    setNumber(value - 1, false, false)
  }

  const handleIncrement = () => {
    setNumber(value + 1, false, true)
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = +e.target.value;
    if (!isNaN(inputValue)) {
      if (maxValue && inputValue > maxValue) {
        setTempValue(maxValue);
        return
      }
      setTempValue(inputValue);
    }
  }

  const setRoundedCount = () => {
    if (typeof tempValue === 'number') {
      setNumber(tempValue, true)
      setTempValue(null)
    }
  }

  const onHandleInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") setRoundedCount()
  }

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  return (
    <div className='flex gap-1 items-center max-h-[32px]'>
      <IconButton onClick={handleDecrement} disabled={value === 0 || value === minValue} className={clsx(sizes[forcedSize], iconSizes[forcedSize], `!bg-neutral disabled:!bg-gray-2 text-accent ${rounded}`)}>
        <MinusIcon className="w-3 h-3" />
      </IconButton>
      <TextBox disabled={disabled} onChange={handleOnChange} onKeyDown={onHandleInput} onBlur={setRoundedCount} value={tempValue ? tempValue : value} className={clsx(sizes[THEME_DECRAFT ? "big" : size], "!p-0 text-center max-w-[52px]")} />
      <IconButton onClick={handleIncrement} disabled={disabled || (value === maxValue) || disableIncrement} className={clsx(sizes[forcedSize], iconSizes[forcedSize], `!bg-neutral disabled:!bg-gray-2 text-accent ${rounded}`)}>
        <PlusIcon className="w-3 h-3" />
      </IconButton>
    </div>
  );
}

export default Counter;