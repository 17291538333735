import * as CheckboxBase from "@radix-ui/react-checkbox";
import * as Label from "@radix-ui/react-label";
import clsx from "clsx";
import {forwardRef, ReactNode} from "react";
import CheckMarkIcon from "../../../../assets/icons/CheckMarkIcon";
import { getRandomId } from "../../../../utils/reusedFunks";


interface Props {
	label?: string | ReactNode;
	checked?: CheckboxBase.CheckedState;
	disabled?: boolean;
	onCheckedChange?(checked: CheckboxBase.CheckedState): void;
	image?: string;
	textNoclick?:boolean;

	styleClasses?: {
		container?: string;
		root?: string;
		indicator?: string;
		label?: string;
	};
}

const Checkbox = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
	const id = getRandomId("cb");
	return (
		<div
			ref={ref}
			className={clsx("flex items-center gap-2 w-full", props.styleClasses?.container)}
		>
			<CheckboxBase.Root
				checked={props.checked}
				className={clsx(
					"flex h-5 w-5 appearance-none items-center justify-center rounded-md border border-gray-3 bg-white transition-all",
					"outline-accent data-[state=checked]:border-accent data-[state=checked]:bg-accent",
					"disabled:data-[state=checked]:border-neutral disabled:data-[state=checked]:bg-neutral",
					"enabled:data-[state=indeterminate]:border-2 enabled:data-[state=indeterminate]:border-accent",
					"disabled:data-[state=unchecked]:border-neutral",
					props.styleClasses?.root,
				)}
				disabled={props.disabled}
				id={id}
				onCheckedChange={(checked) => props.onCheckedChange?.(checked)}
			>
				<CheckboxBase.Indicator
					className={clsx("text-white", props.styleClasses?.indicator)}
				>
					<CheckMarkIcon size={14} />
				</CheckboxBase.Indicator>
			</CheckboxBase.Root>
			{props.image ? (
				<div className="relative h-6 w-6 rounded-lg">
					<img
						className="max-h-full max-w-full rounded-lg object-contain"
						src={props.image}
                        alt="#"
					/>
				</div>
			) : null}
			{props.label ? (
				<>
				{!props.textNoclick ? <Label.Root onClick={(e) => {
					if (props.textNoclick) {
						e.preventDefault();
						e.stopPropagation();
						return
					}
				}} className={clsx("text-sm", props.styleClasses?.label)} htmlFor={id}>
					{props.label}
				</Label.Root>: <span className="text-sm">{props.label}</span>}</>
			) : null}

		</div>
	);
});

Checkbox.displayName = "Checkbox";

export default Checkbox;
