import { FC, useState } from "react";
import ModalBase from "../UI/modals/ModalBase";
import IconButton from "../UI/buttons/IconButton";
import CrossIcon from "../../assets/icons/CrossIcon";
import Select from "../UI/inputs/dropdown/Select";
import TextBox from "../UI/inputs/textInput/TextBox";
import Button from "../UI/buttons/Button";
import { CreatePartnerRequest } from "../../API/admin/partnersApi/partnersInterfaces";
import {
  onGeneratePassword,
  onTextCopy,
} from "../pages/basicPages/utils/utils";
import PencilIcon from "../../assets/icons/PencilIcon";
import Tooltip from "../UI/Tooltip/Tooltip";
import CopyIcon from "../../assets/icons/CopyIcon";
import { PARTNER_CATEGORIES, PARTNER_TYPE } from "../../assets/enums/Partner";
import { checkLogin } from "../../utils/validators";
import { THEME_DECRAFT } from "../../assets/enums/Themes";

interface IProps {
  isOpen: boolean;
  toggleOpen: () => void;
  onCreatePartner: (partnerData: CreatePartnerRequest) => void;
}

const CreatePartnerModal: FC<IProps> = ({
  isOpen,
  toggleOpen,
  onCreatePartner,
}) => {
  const [selectedCaregory, setSelectedCategory] =
    useState<PARTNER_TYPE>(PARTNER_TYPE.Exchange);
  const [partnerName, setPartnerName] = useState<string>();
  const [partnerLogin, setPartnerLogin] = useState<string>();
  const [partnerPassword, setPartnerPassword] = useState<string>("");
  const [partnerB2bLink, setPartnerB2bLink] = useState<string>();
  const [partnerB2cLink, setPartnerB2cLink] = useState<string>();

  const isForExchange =
    selectedCaregory === PARTNER_TYPE.Exchange || selectedCaregory === PARTNER_TYPE.BasketExchange;
  const isForBasket =
    selectedCaregory === PARTNER_TYPE.Basket || selectedCaregory === PARTNER_TYPE.BasketExchange;

  const isValidForCreate =
    selectedCaregory &&
    partnerName &&
    partnerLogin &&
    partnerPassword 
   
  const onCreate = () => {
    if (isValidForCreate) {
      const createPartnerData = {
        category: selectedCaregory,
        name: partnerName,
        login: partnerLogin,
        password: partnerPassword,
      } as CreatePartnerRequest;

      if (isForBasket) createPartnerData.b2cLink = partnerB2cLink;
      if (isForExchange) createPartnerData.b2bLink = partnerB2bLink;

      onCreatePartner(createPartnerData);
    }
  };

  const onSetLogin = (str: string) => {
    if (!checkLogin(str) && str !== "") return
    setPartnerLogin(str)
  }

  return (
    <ModalBase className="rounded-2xl" onOpenChange={toggleOpen} open={isOpen}>
      <div className="w-[320px] flex flex-col items-center">
        <span className="w-full flex items-start justify-end -mt-6 -mr-16">
          <IconButton className={`${THEME_DECRAFT && "text-white hover:text-accent transition-all"}`} onClick={toggleOpen} size={"small"}>
            <CrossIcon size={THEME_DECRAFT ? 24 : 16} />
          </IconButton>
        </span>
        <div className="w-full flex flex-col items-center gap-6 -mt-4">
          <h1 className="w-full font-semibold text-2xl select-none">
            Создание партнера
          </h1>
          <div className="w-full">
            <Select
              onValueChange={(value: PARTNER_TYPE) =>
                setSelectedCategory(value)
              }
              placeholder="Категория партнера"
              getItemTitle={(item) => item.name}
              getItemValue={(item) => item.value}
              items={PARTNER_CATEGORIES}
            />
          </div>
          <div className="w-full">
            <TextBox
              onChangeText={(text: string) => setPartnerName(text)}
              label="Имя партнера"
            />
          </div>
          <div className="w-full">
            <TextBox
              value={partnerLogin}
              onChangeText={(text: string) => onSetLogin(text)}
              label="Логин партнера"
            />
          </div>
          {isForExchange && (
            <div className="w-full">
              <TextBox
                value={partnerB2bLink}
                onChangeText={(text: string) => setPartnerB2bLink(text)}
                label="Точка доступа b2b"
              />
            </div>
          )}

          {isForBasket && (
            <div className="w-full">
              <TextBox
                value={partnerB2cLink}
                onChangeText={(text: string) => setPartnerB2cLink(text)}
                label="Точка доступа b2c"
              />
            </div>
          )}
          <div className="flex items-center justify-between gap-2 w-full">
            <div className="w-full">
              <TextBox
                type="password"
                value={partnerPassword}
                onChangeText={(text: string) => setPartnerPassword(text)}
                label="Пароль партнера"
              />
            </div>
            <div className="flex gap-2">
              <Tooltip text="Сгенерировать новый пароль">
                <IconButton
                  size="big"
                  className=" !text-lg"
                  variant="primary"
                  onClick={() => setPartnerPassword(onGeneratePassword())}
                >
                  <PencilIcon size={18} />
                </IconButton>
              </Tooltip>
              <Tooltip text="Скопировать пароль">
                <IconButton
                  size="big"
                  className=" !text-lg"
                  variant="primary"
                  onClick={() => onTextCopy(partnerPassword, "Пароль")}
                >
                  <CopyIcon size={18} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="w-full">
            <Button onClick={onCreate} disabled={!isValidForCreate} noFish>
              Создать партнера
            </Button>
          </div>
        </div>
      </div>
    </ModalBase>
  );
};

export default CreatePartnerModal;
