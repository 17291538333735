import { FC, useEffect, useState } from "react";
import OrderList from "./components/OrderList";
import ScenariosList from "./components/Scenarios/ScenariosList";
import { IReference, UnllocatedReference } from "../../../../assets/interfaces/IReference";
import { Basket, IScenario } from "../../../../assets/interfaces/IScenario";
import { GetScenarios, UploadSpecJson } from "../../../../API/base/exchangeApi/exchangeFunctions";
import { getScenariosResponse, selectedParntersFilter } from "../../../../API/base/exchangeApi/exchangeInterfaces";
import { ResponseContainer } from "../../../../API/BaseApi";
import { IPartner } from "../../../../assets/interfaces/IPartner";
import { GetPayload } from "../../../../API/base/payloadApi/payloadFunctions";
import { GetPayloadResponse } from "../../../../API/base/payloadApi/payloadInterfaces";
import { useLocation } from "react-router-dom";
import { IOrder } from "../../../../assets/interfaces/IOrder";
import ExpandOrder from "../OrderHistoryPage/components/ExpandOrder";
import { toast } from "react-toastify";
import Loader from "../../../UI/loader/loader";
import { roundedCount } from "../utils/utils";

interface IProps {
    cityId: string;
}
export interface ExchangeNavigateEvent {
    order: IOrder,
    eventType: "repeatOrder" | "makeBasket"
}

const ExchangePage: FC<IProps> = (props: IProps) => {
    const scenariosPageSize = 20
    const [references, setReferences] = useState<IReference[]>([]);
    const [scenarios, setScenarios] = useState<IScenario[]>();
    const [scenariosLoaded, setScenariosloaded] = useState<boolean>(false);
    const [partners, setPartners] = useState<IPartner[]>([])
    const [partnersFilter, setPartnersFilter] = useState<selectedParntersFilter>({ excludePartners: [], requiredPartners: [], cityId: props.cityId })
    const [repeatableScenario, setRepeatableScenario] = useState<IScenario>()
    const [makedOrder, setMakedOrder] = useState<IOrder>()
    const [scenariosIsLoading, setScenariosIsLoading] = useState<boolean>(false)
    const [linkRefsLoading, setLinkRefsLoading] = useState<boolean>(false)
    const [unllocatedReferences, setUnllocatedReferences] = useState<UnllocatedReference[]>()
    const [scenarioIsOpen, setScenarioIsOpen] = useState<boolean>(false)

    const location = useLocation();

    useEffect(() => {
        if (location.state) {
            onSetDataFromOrder(location.state)
        }
    }, [location.state])

    useEffect(() => {
        onGetPartners()
        const searchParams = new URLSearchParams(location.search);
        const specUuid = searchParams.get('id') || localStorage.getItem("linkSpecId")
        if (specUuid) {
            localStorage.removeItem("linkSpecId")
            onSetRefsFromSpecLink(specUuid)
        }
    }, [])

    useEffect(() => {
        let needUpdate = false
        const newReferences = references.map(ref => {
            const newCount = roundedCount(ref.count || 0, ref.multiple, ref.inStockCount)
            if (newCount !== ref.count) {
                needUpdate = true
            }
            return {...ref, count: newCount}
        });
        if (needUpdate) setReferences(newReferences)
        
    }, [references])

    useEffect(() => {
        setScenariosloaded(false);
        if (references.length === 0) {
            setScenarios(undefined)
        }
        if (location.state && location.state.eventType === "makeBasket" && references.length > 0) {
            onGetScenarios(0)
            location.state = undefined
        }
    }, [references])

    useEffect(() => {
        if (scenarios) {
            onGetScenarios(0)
        }
    }, [partnersFilter])

    const onSetRefsFromSpecLink = (specUuid: string) => {
        setLinkRefsLoading(true)
        UploadSpecJson(specUuid).then((r) => {
            if (r.status !== "error" && r.data?.references) {
                setReferences(r.data.references)
            }
            else {
                toast.error("Не удалось получить референсы по ссылке")
            }
            setLinkRefsLoading(false)
        })
    }

    const onSetDataFromOrder = (eventData: ExchangeNavigateEvent) => {
        const orderData = { ...eventData.order }
        const basketRefs = [] as IReference[];
        for (const basket of orderData.baskets) {
            for (const ref of basket.references) {
                const basketRefsArrIndex = basketRefs.findIndex((r) => r.referenceSku === ref.referenceSku)
                if (basketRefsArrIndex >= 0) {
                    //@ts-ignore
                    basketRefs[basketRefsArrIndex] = { ...basketRefs[basketRefsArrIndex], count: basketRefs[basketRefsArrIndex].count as number + ref.count }
                }
                else {
                    basketRefs.push(ref)
                }
            }
        }

        if (eventData.eventType === "repeatOrder") {
            const scenarioBaskets = orderData.baskets.map((b) => {
                const basketRefs = b.references.map((r) => {
                    const refStockCount = r.inStockCount || 0
                    return { ...r, count: r.count as number <= refStockCount ? r.count : refStockCount } as IReference
                })
                return { partnerImg: b.partnerImg, partnerName: b.partnerName, references: basketRefs, partnerId: b.partnerId } as Basket
            })
            setRepeatableScenario({ scenarioId: orderData.orderId, baskets: scenarioBaskets })
        }

        setReferences(basketRefs)
    }

    const onGetPartners = () => {
        GetPayload({ partners: true }).then((r: ResponseContainer<GetPayloadResponse>) => {
            if (r.status !== "error") {
                setPartners(r.data?.partners as IPartner[])
            }
        })
    }

    const onGetScenarios = (page: number) => {
        const refs = references.map((r) => {
            return { referenceSku: r.referenceSku, count: r.count as number }
        })
        const excludePartners = partnersFilter.excludePartners.map((partnerName: string) => {
            return partners.find((r: IPartner) => r.name === partnerName)?.id
        })
        const requiredPartners = partnersFilter.requiredPartners.map((partnerName: string) => {
            return partners.find((r: IPartner) => r.name === partnerName)?.id
        })
        const filter: selectedParntersFilter = { excludePartners: excludePartners as string[], requiredPartners: requiredPartners as string[], cityId: props.cityId }
        if (page === 0) setScenariosIsLoading(true)
        GetScenarios({ pagination: { page: page, pageSize: scenariosPageSize }, references: refs, filter: filter }).then((r: ResponseContainer<getScenariosResponse>) => {
            if (r.status !== "error") {
                setScenarios(r.data?.scenarios as IScenario[])
                setScenariosloaded(true);
            }
            else {
                toast.error("Ошибка загрузки сценариев")
            }
            setScenariosIsLoading(false)
        })
    }

    return (<div className="h-[calc(100%_-_52px)] w-full">
        <div className="flex h-full overflow-hidden  w-full z-10">
            <div className="flex-[13] border-r border-r-gray-2">
                {!linkRefsLoading ? <OrderList onGetScenarios={() => onGetScenarios(0)} updateReferences={setReferences} scriptsLoaded={scenariosLoaded} references={references} unllocatedReferences={unllocatedReferences} scenarioIsOpen={scenarioIsOpen} /> : <Loader />}
            </div>
            <div className="flex-[23]">
                <ScenariosList isLoading={scenariosIsLoading} setMakedOrder={setMakedOrder} repeatableScenario={repeatableScenario}
                    scenariosLoaded={scenariosLoaded} filter={partnersFilter} setFilter={setPartnersFilter}
                    partners={partners} basketReferences={references} scenarios={scenarios} getUnllocatedReferences={setUnllocatedReferences} setScenarioIsOpen={setScenarioIsOpen} />
            </div>
        </div>
        {makedOrder && <div className="w-full h-full absolute top-0 left-0 bg-white z-20" onClick={(e) => e.preventDefault()}>
            <ExpandOrder isNewOrder order={makedOrder} setExpandOrder={() => setMakedOrder(undefined)} />
        </div>}
    </div>);
}

export default ExchangePage;