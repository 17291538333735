import React, { FC, ReactNode } from "react";
import CrossIcon from "../../assets/icons/CrossIcon";
import Button from "../UI/buttons/Button";
import { THEME_DECRAFT } from "../../assets/enums/Themes";


interface IProps {
    isOpen: boolean,
    toggleOpen: (open: boolean) => void;

}

enum themeBgColor {
    default = "bg-[#3c3c3c]",
    decraft = "bg-white"
}

enum themeTextColor {
    default = "text-[#787f86]",
    decraft = "text-black"
}

enum themeIconColor {
    default = "text-white",
    decraft = "text-accent"
}

const bgColor = THEME_DECRAFT ? themeBgColor.decraft : themeBgColor.default
const textColor = THEME_DECRAFT ? themeTextColor.decraft : themeTextColor.default
const iconColor = THEME_DECRAFT ? themeIconColor.decraft : themeIconColor.default

const COOKIE_LINK = "https://systeme.ru/about/legal/cookie-policy"


const CookieModal: FC<IProps> = ({ isOpen, toggleOpen }) => {

    const themeAcceptButton: { default: ReactNode, decraft: ReactNode } = {
        default: <Button size="small" noFish variant="primary" onClick={() => toggleOpen(true)}>Принять</Button>,
        decraft: <Button noFish className="rounded-lg px-[45px]" onClick={() => toggleOpen(true)}>Принять</Button>
    }

    if (!isOpen) return null;

    return (
        <div className={`${bgColor} bg-[#3c3c3c] fixed w-[100%] h-[84px] bottom-0 flex justify-center text-[14px]`}>
            <div className="w-[1200px] flex px-[20px] items-center justify-between">
                <div className={`w-[650px] ${textColor}`}>
                    Мы используем cookie-файлы для улучшения предоставляемых услуг.
                    Продолжая навигацию по сайту, вы соглашаетесь
                    <a href={COOKIE_LINK} className="text-[#00ac86]"> с правилами использования cookie-файлов</a>.
                </div>
                <div className="flex items-center gap-[60px] max-[850px]:gap-[5px] max-[850px]:flex-col">
                    {THEME_DECRAFT ? themeAcceptButton.decraft : themeAcceptButton.default}
                    <button onClick={() => toggleOpen(false)}><CrossIcon size={20} className={`${iconColor} cursor-pointer`} /></button>
                </div>
            </div>
        </div>
    );
};

export default CookieModal;
