import { FC, useEffect, useState } from "react";
import ModalBase from "../UI/modals/ModalBase";
import IconButton from "../UI/buttons/IconButton";
import CrossIcon from "../../assets/icons/CrossIcon";
import Button from "../UI/buttons/Button";
import clsx from "clsx";
import TextBoxDefault from "../UI/inputs/textInput/TextBoxDefault";
import Select from "../UI/inputs/dropdown/Select";
import { AdminRole } from "../../API/admin/adminAuthApi/AdminAuthInterfaces";
import { CreateAdminRequest, IAdminUser } from "../../API/admin/adminsApi/adminsInterfaces";
import { IPartner } from "../../assets/interfaces/IPartner";
import SearchBox from "../UI/inputs/textInput/SearchBox";
import ScrollArea from "../UI/wrappers/ScrollArea";
import Checkbox from "../UI/inputs/checkBox/CheckBox";
import systemeIcon from "../../assets/systemeIcon.svg"
import { THEME_DECRAFT } from "../../assets/enums/Themes";


interface IProps {
    isOpen: boolean;
    toggleOpen: () => void;
    createFunk?: (data: CreateAdminRequest) => void;
    editFunk?: (data: IAdminUser) => void;
    className?: string;
    renderFor: "edit" | "create";
    adminData?: IAdminUser;
    partners: IPartner[];
}

export enum AdminRoles {
    Administtrator = "admin",
    Manager = "manager"
}

const adminRoles = [{
    name: "Администратор",
    value: "admin" as AdminRole
},
{
    name: "Менеджер",
    value: "manager" as AdminRole
},
]

const CreateOrEditAdminModal: FC<IProps> = ({ isOpen, toggleOpen, className, createFunk, editFunk, renderFor, adminData, partners }) => {
    const [adminName, setAdminName] = useState<string>();
    const [adminLastName, setAdminLastName] = useState<string>();
    const [adminLogin, setAdminLogin] = useState<string>();
    const [adminRole, setAdminRole] = useState<AdminRole>();
    const [adminPartners, setAdminPartners] = useState<string[]>([]);
    const [contuneAccess, setContuneAccess] = useState<boolean>(false);
    const [newAdminPassword, setNewAdminPassword] = useState<string>();
    const [searchText, setSearchText] = useState<string>()


    useEffect(() => {
        if (renderFor === "edit" && adminData) {
            setAdminName(adminData.firstName);
            setAdminLastName(adminData.lastName);
            setAdminLogin(adminData.login);
            setAdminRole(adminData.role);
            setAdminPartners(adminData.partners || []);
        }
    }, [adminData]);


    useEffect(() => {
        if ((adminName && adminName.length > 0) && (adminLastName && adminLastName.length > 0) && (adminLogin && adminLogin.length > 0) && (adminRole)) {
            if (renderFor === "create" && newAdminPassword && newAdminPassword.length > 0) {
                setContuneAccess(true);
            }
            else {
                setContuneAccess(false);
            }
            if (renderFor === "edit") {
                if (adminName !== adminData?.firstName ||
                    adminLastName !== adminData.lastName ||
                    adminLogin !== adminData.login ||
                    adminRole !== adminData.role ||
                    adminPartners !== adminData.partners) {
                    setContuneAccess(true);
                } else {
                    setContuneAccess(false);
                }
            }
        } else {
            setContuneAccess(false);
        }
    }, [adminName, adminLastName, adminLogin, adminRole, newAdminPassword, adminPartners]);

    const onSave = () => {
        if (renderFor === "create") {
            let data: CreateAdminRequest = {
                lastName: adminLastName as string,
                firstName: adminName as string,
                login: adminLogin as string,
                partners: adminPartners as string[],
                role: adminRole as AdminRole,
                password: newAdminPassword as string
            }
            createFunk && createFunk(data)
        }
        if (renderFor === "edit") {
            let data: IAdminUser = {
                lastName: adminLastName as string,
                firstName: adminName as string,
                login: adminLogin as string,
                partners: adminPartners as string[],
                role: adminRole as AdminRole,
                id: adminData?.id
            }
            editFunk && editFunk(data)
        }
    }

    const onTogglePartnerSelect = (partnerId: string) => {
        const isSelectedPartner = adminPartners.findIndex((adminPartner: string) => adminPartner === partnerId) >= 0
        if (!isSelectedPartner) {
            setAdminPartners([...adminPartners, partnerId])
        }
        else {
            setAdminPartners(adminPartners.filter((p) => p !== partnerId))
        }
    }

    const showPartnersList = () => {
        if (!searchText) {
            return partners.map((p) => {
                const isSelectedPartner = adminPartners.findIndex((adminPartner: string) => adminPartner === p.id) >= 0
                return <div key={p.id} className="w-full h-12  border-b border-gray-2 py-2 flex items-center gap-4">
                    <div>
                        <Checkbox checked={isSelectedPartner} onCheckedChange={() => onTogglePartnerSelect(p.id)} />
                    </div>
                    <img className="!w-8 !h-8 select-none rounded-sm" src={p.avatar || systemeIcon} alt="IMG" />
                    <p>{p.name}</p>
                </div>
            })
        }
        else {
            return partners.filter((p: IPartner) => p.name.toLowerCase().includes(searchText.toLowerCase()))
                .map((p) => {
                    const isSelectedPartner = adminPartners.findIndex((adminPartner: string) => adminPartner === p.id) >= 0
                    return <div key={p.id} className="w-full h-12  border-b border-gray-2 py-2 flex items-center gap-4">
                        <div>
                            <Checkbox checked={isSelectedPartner} onCheckedChange={() => onTogglePartnerSelect(p.id)} />
                        </div>
                        <img className="!w-8 !h-8 select-none rounded-sm" src={p.avatar} alt="IMG" />
                        <p>{p.name}</p>
                    </div>
                })
        }
    }

    return (
        <ModalBase className={clsx("rounded-lg", className, "w-[1100px] h-[700px] pb-3")} onOpenChange={toggleOpen} open={isOpen}>
            <div className="h-full w-full flex flex-col items-center">
                <span className="w-full flex items-start justify-end -mt-6 -mr-16">
                    <IconButton className={`${THEME_DECRAFT && "text-white hover:text-accent transition-all"}`} onClick={toggleOpen} size={"small"}><CrossIcon size={THEME_DECRAFT ? 24 : 16} /></IconButton>
                </span>
                <div className="flex-1 w-full flex flex-col items-center gap-3 -mt-2 overflow-hidden">
                    <h1 className="w-full font-semibold text-xl">
                        {renderFor === "edit"
                            ? "Редактировать администратора"
                            : "Добавить администратора"}
                    </h1>
                    <div className="flex-1 w-full flex border-y border-gray-1 overflow-hidden">
                        <div className="flex-1 flex flex-col gap-6 border-r border-gray-1 py-4 pr-8">
                            <h1 className="w-full font-semibold text-lg">
                                Основная информация
                            </h1>
                            <div className="flex w-full gap-6">
                                <span className="flex-1">
                                    <TextBoxDefault
                                        placeholder="Введите данные"
                                        error={adminName === ""}
                                        onChange={(e) => setAdminName(e.target.value)}
                                        value={adminName}
                                        label="Имя"
                                    />
                                </span>
                                <span className="flex-1">
                                    <TextBoxDefault
                                        placeholder="Введите данные"
                                        error={adminLastName === ""}
                                        onChange={(e) => setAdminLastName(e.target.value)}
                                        value={adminLastName}
                                        label="Фамилия"
                                    />
                                </span>
                            </div>
                            <div className="flex w-full gap-6">
                                {renderFor === "create" && <span className="flex-1">
                                    <TextBoxDefault
                                        placeholder="Введите пароль"
                                        error={newAdminPassword === ""}
                                        onChange={(e) => setNewAdminPassword(e.target.value)}
                                        value={newAdminPassword}
                                        label="Пароль"
                                    />
                                </span>}
                                <span className="flex-1">
                                    <Select
                                        label="Роль"
                                        placeholder="Выберите роль"
                                        value={adminRole}
                                        onValueChange={(role: AdminRole) => { setAdminRole(role) }}
                                        items={adminRoles}
                                        getItemTitle={function (item: { name: string, value: string }): string {
                                            return item.name;
                                        }} getItemValue={function (item: { name: string, value: string }): string {
                                            return item.value;
                                        }} />
                                </span>
                            </div>
                            <span className="flex-1">
                                <TextBoxDefault
                                    placeholder="Введите данные"
                                    error={adminLogin === ""}
                                    onChange={(e) => setAdminLogin(e.target.value)}
                                    value={adminLogin}
                                    label="Логин"
                                />
                            </span>
                        </div>
                        <div className="flex-1 flex flex-col gap-6 border-gray-1 pt-4 pb-2 pl-8 overflow-hidden">
                            <h1 className="w-full font-semibold text-lg">
                                Партнеры
                            </h1>
                            <SearchBox onClear={() => setSearchText(undefined)} onChangeText={(text: string) => setSearchText(text)} placeholder="Поиск партнеров" />
                            <div className="flex-1 flex flex-col overflow-hidden">
                                <span className="w-full h-6 bg-gray-1 flex items-center text-gray-4 pl-1 border-b border-gray-2">
                                    Список партнеров
                                </span>
                                <div className="w-full flex-1 overflow-hidden">
                                    <ScrollArea>
                                        {showPartnersList()}
                                    </ScrollArea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-4 w-full">
                        <Button
                            disabled={!contuneAccess}
                            onClick={onSave}
                            className="rounded-lg w-auto"
                            noFish
                        >
                            {renderFor === "edit" ? "Сохранить администратора" : "Добавить администратора"}
                        </Button>
                        <Button onClick={toggleOpen} className="rounded-lg w-auto" noFish variant="secondary">
                            Отмена
                        </Button>
                    </div>
                </div>
            </div>
        </ModalBase>
    );
};

export default CreateOrEditAdminModal;
