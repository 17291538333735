import clsx from "clsx";
import { FC } from "react";
import logoFooterDecraft from "../../assets/logoFooterDecraft.svg"
import VkIcon from "../../assets/icons/VkIcon";
import YouTubeIcon from "../../assets/icons/YouTubeIcon";
import TelegramIcon from "../../assets/icons/TelegramIcon";
import ArrowRightUpIcon from "../../assets/icons/ArrowRightUpIcon";

interface IProps {
    isMobile: boolean
}

const LendingFooterDecraft: FC<IProps> = (props: IProps) => {
    return (<div className={clsx("w-full bg-accent p-4 sm:p-6 flex", "flex-col sm:flex-row gap-1")}>
        <div className="sm:flex-1 flex flex-col xl:flex-row gap-8 sm:gap-10">
            <div className="xl:flex-1">
                <img src={logoFooterDecraft} />
            </div>
            <div className="flex-1 h-full flex flex-col gap-1 sm:gap-2 items-start">
                <p className="text-[#0D0D0DB2] font-semibold text-sm pb-2 sm:pb-0">
                    Центр Поддержки Клиентов
                </p>
                <p className="flex flex-col text-white text-xl font-semibold">
                    <a className="hover:text-black transition-colors" href="tel:8 800 200 64 46">8 800 200 64 46</a>
                    <a className="hover:text-black transition-colors" href="tel:+7 495 777 99 88">8 495 777 99 88</a>
                </p>
                <a className="text-white mb-4 hover:text-black transition-colors" href="mailto:support@systeme.ru">support@systeme.ru</a>
                {!props.isMobile && <a target="_blank" href="https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh"
                    className="text-white text-xs w-fit hover:text-black transition-colors">Политика конфиденциальности © 2023 <br /> «Декрафт»  </a>}
            </div>
        </div>
        <div className="flex-1 flex flex-col sm:flex-col-reverse xl:flex-row gap-6 sm:gap-10">
            <div className="sm:flex-1 sm:h-full flex flex-col gap-1">
                <p className="text-[#0D0D0DB2] font-semibold ">
                    Адрес
                </p>
                <p className="text-white text-sm pb-2 sm:pb-0">Москва, ул. Двинцев, д. 12, корп.1, <br /> здание «А»</p>
                <p className="text-[#0D0D0DB2] font-semibold ">
                    <>Контакты для прессы</>
                </p>
                <p className="text-white text-sm mb-2 hover:text-black">
                    <a href="mailto:systeme@m-p.ru">systeme@m-p.ru</a>
                </p>

            </div>
            <div className="flex flex-1 flex-col gap-[32px]">
                <div className="flex gap-3 pb-2 sm:pb-0">
                    <a className="text-accent bg-white h-11 w-11 flex items-center justify-center rounded-full hover:bg-accent hover:text-white transition-colors" target="_blank" href="https://vk.com/systemeelectric"><VkIcon /></a>
                    <a className="text-accent bg-white h-11 w-11 flex items-center justify-center rounded-full hover:bg-accent hover:text-white transition-colors" target="_blank" href="https://www.youtube.com/channel/UCfSQiFykGRQRb6dq1-mznrw"><YouTubeIcon /></a>
                    <a className="text-accent bg-white h-11 w-11 flex items-center justify-center rounded-full hover:bg-accent hover:text-white transition-colors" target="_blank" href="https://t.me/CCCSupportSysteme_bot"><TelegramIcon /></a>
                </div>
                <div className="flex flex-col gap-[12px] items-start">
                    <a className="flex text-white hover:bg-[#00000026] rounded-[4px] p-[4px]" href="https://systeme.ru/" target="_blank"><ArrowRightUpIcon /> Systeme Electric</a>
                    <a className="flex text-white hover:bg-[#00000026] rounded-[4px] p-[4px]" href="https://systemesoft.ru/" target="_blank"><ArrowRightUpIcon /> Systeme Soft</a>
                    <a className="flex text-white hover:bg-[#00000026] rounded-[4px] p-[4px]" href="https://www.mtrele.ru/" target="_blank"><ArrowRightUpIcon /> Механотроника</a>
                </div>
            </div>
        </div>
    </div>);
}

export default LendingFooterDecraft;