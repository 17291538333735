import { FC, useEffect, useState } from "react";
import SearchBox from "../../../UI/inputs/textInput/SearchBox";
import Button from "../../../UI/buttons/Button";
import PlusIcon from "../../../../assets/icons/PlusIcon";
import PencilIcon from "../../../../assets/icons/PencilIcon";
import TrashIcon from "../../../../assets/icons/TrashIcon";
import ScrollArea from "../../../UI/wrappers/ScrollArea";
import IconButton from "../../../UI/buttons/IconButton";
import CreateOrEditUserModal from "../../../modals/CreateOrEditUserModal";
import { IUser } from "../../../../assets/interfaces/IUser";
import ConfirmModal from "../../../modals/ConfirmModal";
import { CreateUser, DeleteUser, EditUser, GetUsers } from "../../../../API/admin/usersApi/usersFunctions";
import { CreateUserRequest, EditUserRequest, GetUsersRequest } from "../../../../API/admin/usersApi/usersInterfaces";
import { scrollListTop } from "../../../../utils/reusedFunks";
import Loader from "../../../UI/loader/loader";
import { toast } from "react-toastify";
import LoaderModal from "../../../UI/loader/LoaderModal";
import * as XLSX from "xlsx";
interface IProps {

}

const Users: FC<IProps> = ({ }) => {
  const usersPageSize = 20;
  const [users, setUsers] = useState<IUser[]>([]);
  const [editUser, setEditUser] = useState<IUser>();
  const [deleteUser, setDeleteUser] = useState<IUser>();
  const [createUserModal, setCreateUserModal] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>();
  const [page, setPage] = useState<number>(0);
  const [totalUsersCount, setTotalUsersCount] = useState(0)
  const [getUsersLoading, setGetUsersLoading] = useState<boolean>(false)
  const [userEventLoading, setUserEventLoading] = useState<boolean>(false)

  useEffect(() => {
    if (page > 0) {
      onGetUsers(page, searchText)
    }
  }, [page])

  const onGetUsers = (page: number, searchText: string | undefined) => {
    let reqParams: GetUsersRequest = { pagination: { page: page, pageSize: usersPageSize } }
    if (searchText) {
      reqParams = { ...reqParams, filterBy: { searchText: searchText } }
    }
    if (page === 0) setGetUsersLoading(true)
    GetUsers(reqParams).then((r) => {
      if (r.status !== "error" && r.data) {
        if (page === 0) {
          scrollListTop("mainList")
          setUsers(r.data.users)
          setPage(0)
        }
        else {
          setUsers([...users, ...r.data.users])
        }
        setTotalUsersCount(r.data.totalCount)
      }
      setGetUsersLoading(false)
    })
  }

  const onExportToExcel = (users: IUser[]) => {
    const excelData = users.map((user, index) => ({
      "№": index + 1,
      Пользователи: `${user.firstName} ${user.lastName}`,
      Логин: user.email,
    }));
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    XLSX.writeFile(workbook, "Users.xlsx");
  };

  const onEditUser = (newUserData: EditUserRequest, userId: string) => {
    setUserEventLoading(true)
    EditUser(userId, newUserData).then((r) => {
      if (r.status !== "error") {
        onGetUsers(0, searchText)
        setEditUser(undefined)
        toast.success("Изменения сохранены")
      }
      else {
        toast.error(r.message || "Ошибка при сохранении")
      }
      setUserEventLoading(false)
    })
  }
  const onCreateUser = (userData: CreateUserRequest) => {
    setUserEventLoading(true)
    CreateUser(userData).then((r) => {
      if (r.status !== "error") {
        onGetUsers(0, searchText)
        setCreateUserModal(false)
        toast.success(`Создан пользователь ${userData.firstName} ${userData.lastName}`)
      }
      else {
        toast.error(r.message || "Ошибка создания пользователя")
      }
      setUserEventLoading(false)
    })
  }

  const onDeleteUser = () => {
    setUserEventLoading(true)
    DeleteUser(deleteUser?.id as string).then((r) => {
      if (r.status !== "error") {
        toast.success(`Пользователь ${deleteUser?.firstName} ${deleteUser?.lastName} удален`)
        onGetUsers(0, searchText)
        setDeleteUser(undefined)
      }
      else {
        toast.error(r.message || "Ошибка удаления пользователя")
      }
      setUserEventLoading(false)
    })
  }

  const onSrollEnd = () => {
    if (totalUsersCount > users.length) {
      setPage(page + 1)
    }
  }
  
  const usersList = users.map((u: IUser, index) => {
    return <div key={u.id} className="w-full flex h-[60px] items-center px-[60px] border-b border-gray-2">
      <p className="flex-1">{index + 1} <span className="ml-6">{u.firstName}</span> {u.lastName}
      </p>
      <div className="w-1/5 justify-start flex items-center gap-1">
        <p className="text-left flex-1">{u.email}</p>
        <IconButton onClick={() => setEditUser(u)}><PencilIcon size={16} /></IconButton>
        <IconButton onClick={() => setDeleteUser(u)}><TrashIcon size={16} /></IconButton>
      </div>
    </div>
  })

  return (
    <div className="flex flex-col w-full gap-8 h-full pt-4">
      <div className="flex items-center w-full px-10 gap-1">
        <div className="flex-1">
          <div className="w-[592px]">
            <SearchBox onClear={() => onGetUsers(0, undefined)}
              onSearchDebounced={(searchText: string) => onGetUsers(0, searchText)}
              onChangeText={setSearchText} placeholder="Поиск" />
          </div>
        </div>
        <Button onClick={() => onExportToExcel(users)} noFish className="w-auto rounded-lg">Скачать выгрузку</Button>
        <Button onClick={() => setCreateUserModal(true)} noFish className="w-auto rounded-lg"><PlusIcon size={16} /> Новый пользователь</Button>
      </div>
      <div className="flex-1 w-full flex flex-col overflow-hidden">
        <div className="w-full h-10 flex items-center bg-gray-1 px-[60px] text-gray-4 border-b border-gray-2 gap-5">
          <span>№</span>
          <span className="flex-1">Пользователи <span className="bg-[#969FA8] text-black px-2 rounded-full">{totalUsersCount}</span></span>
          <span className="text-left w-1/5">Логин</span>
        </div>
        {!getUsersLoading ? <ScrollArea id="mainList" onScrollEnd={onSrollEnd}>
          {usersList}
        </ScrollArea> : <Loader />}
      </div>
      {typeof (editUser) === "object" && <CreateOrEditUserModal
        userData={editUser}
        renderFor="edit"
        editFunk={onEditUser}
        isOpen={typeof (editUser) === "object"}
        toggleOpen={() => setEditUser(undefined)} />}
      {createUserModal && <CreateOrEditUserModal
        renderFor="create"
        createFunk={onCreateUser}
        isOpen={createUserModal}
        toggleOpen={() => setCreateUserModal(false)} />}
      <ConfirmModal
        isDelete
        funk={onDeleteUser}
        toggleOpen={() => setDeleteUser(undefined)}
        headText="Удалить пользователя?"
        text={`Вы действительно хотите удалить пользователя ${deleteUser?.firstName} ${deleteUser?.lastName}?`}
        confirmBtnText="Удалить"
        isOpen={typeof (deleteUser) === "object"} />
      <LoaderModal open={userEventLoading} />
    </div>
  );
}

export default Users;