import clsx from "clsx"
import { FC } from "react"
interface IProps {
    img: any, 
    imgIsLeft: boolean, 
    step: number, 
    stepsCout: number, 
    headText: string, 
    text: string
}
const BigStep: FC<IProps> = (props: IProps) => {
	return (
		<div className={clsx("w-full flex flex-col-reverse items-center sm:flex-row gap-4 xl:gap-8", !props.imgIsLeft && "sm:flex-row-reverse")}>
			<div className="flex-1 h-full flex items-center">
				<img className="select-none bg-gray-1 py-12 sm:py-16 xl:py-24 xl:px-[37px] sm:px-5 px-[10px] xl:rounded-[32px] sm:rounded-[24px] rounded-[16px]" src={props.img}/>
			</div>
			<div className="flex-1 flex h-full items-center justify-center">
				<div className="flex flex-col xl:gap-4 sm:gap-2 h-fit xl:w-[60%] sm:w-[80%]">
					<p className="font-semibold text-gray-3 text-sm xl:text-xl"><span className="text-accent">Шаг {props.step}</span> из {props.stepsCout}</p>
					<h1 className="text-3xl xl:text-5xl text-accent mb-2 font-semibold">{props.headText}</h1>
					<p className="text-gray-3 w-[70%] text-sm xl:text-xl">{props.text}</p>
				</div>
			</div>
		</div>
	)
}

export default BigStep