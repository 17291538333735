import { FC } from "react"
import BigStep from "./BigStep"
import laptopStepOne from "../../../../../assets/images/laptopStepOne.png"
import laptopStepTwo from "../../../../../assets/images/laptopStepTwo.png"
import laptopStepThree from "../../../../../assets/images/laptopStepThree.png"
import laptopStepFour from "../../../../../assets/images/laptopStepFour.png"
import laptopStepOneDecraft from "../../../../../assets/images/laptopStepOneDecraft.png"
import laptopStepTwoDecraft from "../../../../../assets/images/laptopStepTwoDecraft.png"
import laptopStepThreeDecraft from "../../../../../assets/images/laptopStepThreeDecraft.png"
import laptopStepFourDecraft from "../../../../../assets/images/laptopStepFourDecraft.png"
import { THEME_DECRAFT } from "../../../../../assets/enums/Themes"

interface IProps {

}

const themeImg = {
	stepOne: {
		default: laptopStepOne,
		decraft: laptopStepOneDecraft
	},
	stepTwo: {
		default: laptopStepTwo,
		decraft: laptopStepTwoDecraft
	},
	stepThree: {
		default: laptopStepThree,
		decraft: laptopStepThreeDecraft
	},
	stepFour: {
		default: laptopStepFour,
		decraft: laptopStepFourDecraft
	},
}

function selectImg(step: keyof typeof themeImg): string {
	return THEME_DECRAFT ? themeImg[step].decraft : themeImg[step].default;
}

const StepsBig: FC<IProps> = (props: IProps) => {
	return (<div className="w-full flex flex-col gap-12 sm:gap-6">
		<BigStep step={1} stepsCout={4} headText="Выбор товаров" imgIsLeft img={selectImg("stepOne")} text="Выберите товары Systeme Electric и добавьте их в корзину" />
		<BigStep step={2} stepsCout={4} headText="Проверка наличия" imgIsLeft={false} img={selectImg("stepTwo")} text="Сервис проверит наличие выбранных позиций у дистрибьюторов и предложит оптимальные сценарии для закупки" />
		<BigStep step={3} stepsCout={4} headText="Выбор поставщиков" imgIsLeft img={selectImg("stepThree")} text="Выберите один из предложенных сценариев закупки. Вы можете внести корректировки в сценарий, используя удобные фильтры" />
		<BigStep step={4} stepsCout={4} headText="Покупка" imgIsLeft={false} img={selectImg("stepFour")} text="Ваша корзина товаров будет перенесена в интернет-магазин дистрибьютора для дальнейшей закупки товаров" />
	</div>)
}

export default StepsBig