import { P, requestFileGet, requestPost, requestPostForm } from "../../requester";
import { IUploadCostReferences, IUploadMasterDB } from "./dbProductInterfaces";

export function UploadMasterDB(args: IUploadMasterDB) {
	return requestPostForm<null>(P.DB, args);
}

export function UploadCostReferences(args: IUploadCostReferences) {
	return requestPost(P.RefPrices, args)
}

export function DownloadMasterDB(downloadErrorHandler: (errorMsg: string) => void) {
	return requestFileGet(P.DB, "MasterDB", "xlsx", downloadErrorHandler);
}

export function DownloadStock(downloadErrorHandler: (errorMsg: string) => void) {
	return requestFileGet(P.DownloadStocks, "Ostatky", "xlsx", downloadErrorHandler);
}