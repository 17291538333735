import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import React from "react";

import Paper from "../../layout/Paper";
import { THEME_DECRAFT } from "../../../assets/enums/Themes";

export interface ModalProps {
	children: React.ReactNode;
	open?: boolean;
	onOpenChange: (open: boolean) => void;
	className?: string;
	styleClasses?: {
		overlay?: string;
	};
	fromBottom?: boolean
}

enum themeModalStyles {
	defaultStyle = "overflow-hidden",
	decraftStyle = "rounded-tr-[120px] overflow-visible"
}

export interface ModalPropsNoChildren {
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
}

const ModalBase = (props: ModalProps) => {
	return (
		<Dialog.Root open={props.open} onOpenChange={props.onOpenChange}>
			<Dialog.Portal>
				<Dialog.Overlay
					className={clsx(
						"fixed inset-0 z-40 bg-blackout opacity-0 transition-opacity data-[state=open]:opacity-100",
						props.styleClasses?.overlay,
					)}
				/>
				<Dialog.Content className={clsx("fixed z-50 outline-none translate-x-[-50%]",
				 !props.fromBottom ? " left-[50%] top-[50%]  translate-y-[-50%]" : "left-[50%] bottom-0 translate-y-0")}>
					<Paper
						className={clsx(
							"flex max-h-[calc(100vh-5rem)] flex-col gap-6,", THEME_DECRAFT ? themeModalStyles.decraftStyle : themeModalStyles.defaultStyle,
							props.className,
						)}
					>
						{props.children}
					</Paper>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};

export default ModalBase;
